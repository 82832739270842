import { Grid } from '@mui/material';
import * as React from 'react';
import { ICategoryType } from '../../../all-types';
import { CategoryCard } from '../components';


interface ICategoryPanelProps {
    setCategoryTxt: (txt: string) => void;
    categories: ICategoryType[]
}

const CategoryPanel: React.FC<ICategoryPanelProps> = ({ setCategoryTxt, categories: categoryList }) => {
    const ALL_NAME = "ALL";
    const [selectedCategory, setSelectedCategory] = React.useState(ALL_NAME);

    const handleCategorySelect = (txt: string) => {
        setSelectedCategory(txt);
        if (txt === ALL_NAME) {
            setCategoryTxt("");
        } else {
            setCategoryTxt(txt);
        }
    }

    React.useEffect(() => {
        setCategoryTxt("");
    },[])

    return (
        <Grid container>
            <Grid item xs={12} key={"all-category"}>
                <CategoryCard category={{ categoryId: "763573", categoryName: ALL_NAME }}
                    selectedCategory={selectedCategory}
                    onClick={() => handleCategorySelect(ALL_NAME)} />
            </Grid>
            {categoryList?.map((c) => (
                <Grid item xs={12} key={c.categoryId}>
                    <CategoryCard category={c}
                        selectedCategory={selectedCategory}
                        onClick={() => handleCategorySelect(c.categoryName)} />
                </Grid>
            ))}
        </Grid>
    );
};

export { CategoryPanel };

