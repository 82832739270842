import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { IRequestTemplate } from "../../../../all-types/business";
import { axiosEmailClient } from "../../../../api";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
function TemplateComponent() {
  const [rows, setRows] = useState([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [warningDialog, setWarningDialog] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<string>("");
  const [templateForm, setTemplateForm] = useState<Partial<IRequestTemplate>>({
    templateName: "",
    template: "",
    type: "static",
  });

  const columns: GridColDef[] = [
    { field: "_id", headerName: "ID", minWidth: 90, flex: 1 },
    {
      field: "templateName",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      // editable: true,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            <div className="center-container">
              <Grid spacing={3}>
                <EditIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleEdit(params.row)}
                />
                <DeleteIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setWarningDialog(true);
                    setCurrentId(params.row._id as string);
                  }}
                />
              </Grid>
            </div>
          </>
        );
      },
      flex: 1,
    },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTemplateForm({
      ...templateForm,
      [name]: value,
    });
  };

  const handleEdit = async (row: any) => {
    console.log(row);

    setTemplateForm(() => ({
      template: row.template,
      templateName: row.templateName,
      type: row.type,
      _id: row._id,
    }));
    setCurrentId(row._id);
    setDialogOpen(true);
  };

  const handleDelete = async () => {
    console.log(currentId);
    try {
      const req = await axiosEmailClient.delete(`/masters/config/${currentId}`);

      fetchData();
    } catch (err) {
      console.error(err);
    }
    setWarningDialog(false);
  };

  const fetchData = async () => {
    try {
      const req = await axiosEmailClient.get("/masters/template/get-template");
      const { data } = req.data;
      console.log(data);
      setRows(data);
    } catch (err) {
      console.log(err);
    }
  };

  const onSave = async () => {

    if(!validateForm()) return

    try {
      if(currentId ){
        templateForm._id=currentId;
      }
      const req = await axiosEmailClient.post(
        "/masters/template",
        templateForm
      );

      setTemplateForm({
        templateName: "",
        template: "",
      });
      setDialogOpen(false);

      fetchData();
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm=()=>{
    let isValid = true;
    let msg=''
    if(!templateForm.template?.trim()){
      msg+='Template is required \n '
      isValid=false
    }
    if(!templateForm.templateName?.trim()){
      msg+=' Name  is required \n '
      isValid=false
    }
    if(!templateForm.type?.trim()){
      msg+=' Type is required \n '
      isValid=false
    }

      if(!isValid){
        alert(msg)
      }

      return isValid
  }



  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Box
        sx={{ height: "calc(100vh - 214px)", width: "100%", padding: "20px" }}
      >
        <Box display={"flex"} flexDirection={"row"} justifyContent={"end"}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            width={"100%"}
            justifyContent={"space-between"}
          >
            <Typography variant="h6" fontWeight={"400"}>
              Templates
            </Typography>
            <Button
              variant="outlined"
              startIcon={<Add />}
              onClick={() => setDialogOpen(true)}
            >
              {" "}
              Add Item
            </Button>
          </Box>
        </Box>
        <DataGrid
          sx={{ marginTop: "8px" }}
          rows={rows}
          columns={columns}
          getRowId={(row: any) => row._id}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5, 10, 50]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Templates</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              padding: "30px",
              border: "1px solid black",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4} alignSelf={"center"}>
                <TextField
                  sx={{ borderRadius: "50px" }}
                  variant="outlined"
                  value={templateForm.templateName}
                  onChange={handleChange}
                  fullWidth
                  name="templateName"
                  label="Name"
                  placeholder="Enter Template Name"
                ></TextField>
              </Grid>
              <Grid item xs={4} alignSelf={"center"}>
                <TextField
                  sx={{ borderRadius: "6px" }}
                  variant="outlined"
                  onChange={handleChange}
                  fullWidth
                  name="template"
                  label="Template"
                  value={templateForm.template}
                  required
                  placeholder="Enter Template"
                ></TextField>
              </Grid>
              <Grid item xs={4} alignSelf={"center"}>
                <Select
                  sx={{ borderRadius: "6px" }}
                  variant="outlined"
                  onChange={handleChange as any}
                  fullWidth
                  name="type"
                  value={templateForm.type}
                  required
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    <em>Select Template Type</em>
                  </MenuItem>
                  <MenuItem value="static">Static</MenuItem>
                  <MenuItem value="dynamic">Dynamic</MenuItem>
                </Select>
              </Grid>

              <Grid
                item
                xs={4}
                alignSelf={"center"}
                justifyContent={"flex-end"}
              >
                <Button variant="contained" onClick={() => onSave()}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default TemplateComponent;
