import { useCallback } from "react";

export enum QUANTITY_TYPES {
  KG = "KG", // kg
  GRAMS = "GRAMS", // gms
  LITER = "LITER", // liters
  MILLI_LITER = "MILLI_LITER", // milliliters
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  ITEM = "ITEM",
  SLICE = "SLICE",
  PIECE = "PIECE",
  DOZEN = "DOZEN",
}

interface PriceConversionOptions {
  basePrice: number; // Price per KG, LITER, or ITEM (depending on the unit)
  quantity: number; // The quantity in its specified type
}

const weightConversions: Partial<Record<QUANTITY_TYPES, number>> = {
  [QUANTITY_TYPES.KG]: 1,
  [QUANTITY_TYPES.GRAMS]: 1000,
};

const volumeConversions: Partial<Record<QUANTITY_TYPES, number>> = {
  [QUANTITY_TYPES.LITER]: 1,
  [QUANTITY_TYPES.MILLI_LITER]: 1000,
};

const dozenConversions: Partial<Record<QUANTITY_TYPES, number>> = {
    [QUANTITY_TYPES.DOZEN]: 1,
    [QUANTITY_TYPES.PIECE]: 12,
  };

interface ConversionOptions {
  quantity: number; // Input quantity
  fromType: QUANTITY_TYPES; // Current quantity type
  toType: QUANTITY_TYPES; // Desired quantity type within the same family
}

const useDefaultPrice = () => {
  const getDefaultPrice = useCallback(
    ({ basePrice, quantity }: PriceConversionOptions): number => {
      return Number((basePrice / quantity ).toFixed(3));
    },
    []
  );

  const convertQuantity = useCallback(
    ({ quantity, fromType, toType }: ConversionOptions): number => {
      if (fromType === toType) return quantity;

      if (fromType in weightConversions && toType in weightConversions) {
        // Convert within weight family (KG <-> GRAMS)
        const baseInKg = quantity / (weightConversions[fromType] || 1);
        return baseInKg * (weightConversions[toType] || 1);
      }

      if (fromType in volumeConversions && toType in volumeConversions) {
        // Convert within volume family (LITER <-> MILLI_LITER)
        const baseInLiters = quantity / (volumeConversions[fromType] || 1);
        return baseInLiters * (volumeConversions[toType] || 1);
      }

      if (fromType in dozenConversions && toType in dozenConversions) {
        // Convert within volume family (DOZEN <-> PIECE)
        const baseInDozens = quantity / (dozenConversions[fromType] || 1);
        return baseInDozens * (dozenConversions[toType] || 1);
      }

      // For units like ITEM, SLICE, PIECE, DOZEN, etc., no conversion (return quantity directly)
      return quantity;
    },
    []
  );

  return { getDefaultPrice, convertQuantity };
};

export { useDefaultPrice };
