import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { STATUS } from "../../../constants";
import {
  getClientInfo,
  updateClientInfo,
  updatePaytmCardReaderSettings,
  updatePrinterSettings,
} from "../../../services";
import { CLEINT_INFO_INITIAL_DATA } from "./inital-data";
import { ClientInfoType, TaxComponent } from "../../../all-types";

const initialState: {
  data: ClientInfoType;
  status: string;
  error: string;
} = {
  data: CLEINT_INFO_INITIAL_DATA,
  status: STATUS.EMPTY,
  error: "",
};

export const ClientInfoSlice = createSlice({
  name: "clientInfo",
  initialState,
  reducers: {
    setClientInfo: (state, action: PayloadAction<ClientInfoType>) => {
      state.data = action.payload;
    },
    addTaxComponent: (state, action: PayloadAction<TaxComponent>) => {
      let taxList = state.data.taxManager?.taxComponentsList;
      const taxComp = action.payload;
      if (!(Object.keys(taxList).length > 0)) {
        taxList = {};
      } 
      taxList[taxComp.taxName] = taxComp;
      state.data.taxManager = {
        ...state.data.taxManager,
        taxComponentsList: taxList,
      }
    },
    clearClientInfo: (state, action) => {
      state.data = CLEINT_INFO_INITIAL_DATA;
    },
    setConnectionInfo(state, action) {
      if (action.payload.productId && action.payload.vendorId) {
        state.data.printerSettings.productId = action.payload.productId;
        state.data.printerSettings.vendorId = action.payload.vendorId;
      }
      state.data.printerSettings.connectionSettings = {
        ...state.data.printerSettings.connectionSettings,
        ...action.payload,
      };
      state.data.printerSettings.isPrinterConfigured = false;
    },
    setIsPrinterConnected(state, action) {
      state.data.printerSettings.isPrinterConfigured = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientInfo.pending, (state) => {
        state.status = STATUS.LOADING;
      })
      .addCase(getClientInfo.fulfilled, (state, action) => {
        state.status = STATUS.SUCCESS;
        state.data = action.payload;
      })
      .addCase(getClientInfo.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.error.message || "Something went wrong";
      })
      .addCase(updatePrinterSettings.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(updatePaytmCardReaderSettings.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(updateClientInfo.fulfilled, (state, action) => {
        state.data = action.payload;
      });
  },
});

export const { setClientInfo, clearClientInfo, addTaxComponent } =
  ClientInfoSlice.actions;
