import React, { useEffect, useState } from "react";
import { Button, Box, Grid } from "@mui/material";
import SearchInput from "../../components/Input/SearchInput/SearchInput";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import { useTheme } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks";
import { fetchProducts } from "../../redux-store/features/products/productSlice";
import BillingComponent from "../../components/BillingComponent/BillingComponent";
import { useSelector } from "react-redux";
import { RootState } from "../../redux-store/store";
import ItemCard from "../../components/ItemCard";

export interface Props {
  handleOpen: () => void;
}

const Items: React.FC<Props> = ({ handleOpen }) => {
  const [dropdownValue, setDropdownValue] = useState<string | number>("");
  const [hideCart, setHideCart] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showCategories, setShowCategories] = useState(true);

  const theme = useTheme();

  const dispatch = useAppDispatch();
  const products = useAppSelector((state) => state.products.products);
  const productStatus = useAppSelector((state) => state.products.status);
  const error = useAppSelector((state) => state.products.error);
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const { categories } = useAppSelector((state) => state.products);

  useEffect(() => {
    dispatch(fetchProducts());
  }, []);

  // const handleDropdownChange = (
  //   event: React.ChangeEvent<{ value: unknown }>
  // ) => {
  //   setDropdownValue(event.target.value as string);
  // };

  const filteredProducts = products.filter((product) => {
    const query = searchQuery.toLowerCase();

    const productCodeMatch = product.productCode.toString().includes(query);
    const nameMatch = product.name.toLowerCase().includes(query);
    const categoryMatch = product.category.toLowerCase().includes(query);

    const categoryFilter = dropdownValue
      ? product.category === dropdownValue
      : true;

    return (productCodeMatch || nameMatch || categoryMatch) && categoryFilter;
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        backgroundColor: theme.palette.color13.main,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          gap: 2,
          width: "100%",
          px: 3,
          pt: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
          >
            <SearchInput
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Search by name, itemId, Category"
            />
            <Button
              variant={showCategories ? "contained" : "outlined"}
              color="secondary"
              onClick={() => setShowCategories(!showCategories)}
              sx={{ borderRadius: "10px", px: "1.25rem", py: "0.6rem" }}
            >
              Category
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <GridViewOutlinedIcon />
        </Box>
        <Box sx={{ height: "1rem" }}>
          {showCategories && (
            <div>
              <Button
                variant="outlined"
                color="primary"
                key="all"
                value="All"
                sx={{
                  mx: 2,
                  borderRadius: "10px",
                  px: "1.25rem",
                  py: "0.6rem",
                }}
                onClick={() => setDropdownValue("")}
              >
                All
              </Button>
              {categories.map((category) => (
                <Button
                  variant="outlined"
                  color="primary"
                  key={category.categoryId}
                  value={category.categoryName}
                  sx={{
                    mx: 2,
                    borderRadius: "10px",
                    px: "1.25rem",
                    py: "0.6rem",
                  }}
                  onClick={() => setDropdownValue(category.categoryName)}
                >
                  {category.categoryName}
                </Button>
              ))}
            </div>
          )}
        </Box>

        <Grid
          container
          sx={{
            order: { xs: 2, sm: 1 },
            maxHeight: {
              xs: "calc(100vh - 150px)",
              sm: `calc(100vh - 150px)`,
            },
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "20px",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 5px grey",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "darkgrey",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#b30000",
            },
            scrollbarWidth: "thin",
            scrollbarColor: "darkgrey #ffffff",
          }}
        >
          {filteredProducts.length > 0 &&
            filteredProducts.map((product) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                key={product._id}
                sx={{ my: 3 }}
              >
                <ItemCard handleOpen={handleOpen} item={product} />
              </Grid>
            ))}
        </Grid>
      </Box>
      <Box
        sx={{
          flex: 1,
          ml: "auto",
          width: "384px",
          height: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <BillingComponent handleOpen={handleOpen} />
      </Box>
    </Box>
  );
};

export default Items;
