import ReceiptIcon from '@mui/icons-material/Receipt';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import { Alert, Button, Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Cut, Image, Printer as RPrinter, Text, render } from 'react-thermal-printer';
import { LightUSB } from '../../libraries';
import { useAppSelector } from '../../redux-store/hooks';
import { PrinterCard } from './components';


interface USBDevice {
    productName: string;
    manufacturerName: string;
    vendorId: number;
    productId: number;
}

const Printer: React.FC = () => {
    const printerSettings = useAppSelector(state => state.clientInfo?.data?.printerSettings);
    const [device, setDevice] = useState<USBDevice | null>(null);
    const [printerUsb, setPrinterUsb] = useState<LightUSB | null>(null);
    const [printerStatus, setPrinterStatus] = useState(false);


    const loadPrinterSettings = async (isReload = false) => {
        try {
            if (printerSettings) {
                const { vendorId, productId, name } = printerSettings;
                setDevice({
                    vendorId: Number(vendorId), productId: Number(productId),
                    manufacturerName: name,
                    productName: name
                });
                if (vendorId && productId) {
                    const printerObj = new LightUSB(Number(productId), Number(vendorId));
                    const ispaired = await printerObj.isPaired();
                    if (ispaired && isReload) {
                        setPrinterStatus(ispaired)
                    } else {
                        const statusObj = await printerObj.connect();
                        setPrinterStatus(statusObj.status)
                    }
                    setPrinterUsb(printerObj);
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    const addNewPrinter = async () => {
        try {
            const usbObj = await LightUSB.initiateBySelect();
            if (usbObj) {
                const statusObj = await usbObj.connect();
                setPrinterStatus(statusObj.status);
                setPrinterUsb(usbObj);
                setDevice({
                    productId: usbObj?.productId,
                    vendorId: usbObj?.vendorId,
                    manufacturerName: usbObj?.device?.productName,//TODO
                    productName: usbObj?.device?.productName
                })
            }
        } catch (e) {
            console.error(e);
        }
    }

    const testPrint = async () => {
        try {
            const data = await render(
                <RPrinter type='epson' characterSet='pc437_usa'>
                    <Text font={"A"} size={{ width: 2, height: 2 }} align="center">
                        LIGHTWORKS POS
                    </Text>
                    <Image align="center" src={"https://picsum.photos/200/300?grayscale"} />
                    <Text font={"A"} size={{ width: 2, height: 2 }} align="center">
                        TEST PRINT
                    </Text>
                    <Cut />
                </RPrinter>
            );
            if (printerUsb) {
                await printerUsb.print(data);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const loadinitialProcess = async () => {
        await loadPrinterSettings();
    }

    useEffect(() => {
        loadinitialProcess();
    }, []);


    return (
        <Grid container p={6} gap={2}>
            <Grid item xs={12}>
                <Grid item xs={12} md={6} lg={4}>
                    <Stack flexDirection={"row"} justifyContent={"flex-start"} gap={1}>
                        <Button endIcon={<SearchIcon />} variant="outlined"
                            sx={{ height: 60 }} color="primary"
                            onClick={addNewPrinter}>Find New Printer</Button>
                        <Button endIcon={<RefreshIcon />} variant="outlined"
                            sx={{ height: 60 }} color="primary"
                            onClick={() => loadPrinterSettings(true)}> Refresh</Button>
                    </Stack>
                </Grid>
            </Grid>

            {/* <Grid item xs={12}>
      <Grid item xs={12} md={6} lg={4}>
        <Stack flexDirection={"row"} justifyContent={"flex-start"} gap={1}>
          <IconButton onClick={addNewPrinter} color='primary'>
            <SearchIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <IconButton onClick={addNewPrinter} color='primary'>
            <RefreshIcon sx={{ fontSize: 30 }} />
          </IconButton>
        </Stack>
      </Grid>
    </Grid> */}

            <Grid item xs={12}>
                <Grid item xs={12} md={6} lg={4}>
                    <Stack>
                        {printerStatus ? (
                            <Alert variant="filled" severity="success">
                                Connected
                            </Alert>
                        ) : (
                            <Alert variant="filled" severity="error">
                                Disconnected.
                            </Alert>
                        )}
                    </Stack>

                    <Stack>
                        {device && (<PrinterCard device={device} index={0} />)}
                    </Stack>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid item xs={12} md={6} lg={4}>
                    <Stack flexDirection={"row"} justifyContent={"flex-end"} gap={1}>
                        {printerStatus && (
                            <Button startIcon={<ReceiptIcon />} variant="outlined" color="primary"
                                onClick={testPrint} sx={{ height: 60 }}>Test Print</Button>
                        )}
                        <Button startIcon={<SaveIcon />} variant="contained" color="primary"
                            onClick={addNewPrinter} sx={{ height: 60 }}>Save Settings</Button>

                    </Stack>

                </Grid>
            </Grid>
        </Grid>
    );
};

export { Printer };

