import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, Button, CardActionArea } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Product } from "../constants/Interfaces";
import { setSelectedProductForBilling } from "../redux-store/features/products/productSlice";
import { useAppDispatch } from "../redux-store/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../redux-store/store";

export interface ItemCardProps {
  handleOpen: () => void;
  item: Product;
}

export default function ItemCard({ handleOpen, item }: ItemCardProps) {
  //state
  const dispatch = useAppDispatch();
  const cartItems = useSelector((state: RootState) => state.cart.items);

  console.log("cartItems", cartItems);
  console.log("item", item);

  const itemColor = cartItems.some(
    (cartItem) => cartItem.id.split("-")[0] === item._id
  )
    ? "#ECE0D8"
    : "white";

  const isNotAvailableStyle = {
    filter: "grayscale(100%)",
    pointerEvents: "none",
    backgroundColor: "#f0f0f0",
  };

  return (
    <Card
      onClick={() => {
        dispatch(setSelectedProductForBilling(item));
        handleOpen();
      }}
      sx={{
        backgroundColor: itemColor,
        ...(item?.isInStock ? {} : isNotAvailableStyle),
        display: "flex",
        flexDirection: "row", // Arrange children horizontally
        boxShadow: "0px 2px 4px rgba(0, 0, 1, 0.2)",
        width: { xs: "90%", sm: "90%", md: "90%" },
        height: { xs: "auto", sm: "12.8125rem", md: "13.75rem" },
        borderRadius: "10px",
        padding: "1rem",
      }}
    >
      <CardActionArea sx={{ display: "flex", flexDirection: "row" }}>
        <CardMedia
          component="img"
          sx={{ width: "50%", height: "100%", borderRadius: "10px" }} // Occupy left vertical side
          image="./sweet.png"
          alt={item.name}
        />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
            paddingRight: "0px",
            width: "50%", // Occupy right vertical side
            "&:last-child": { paddingBottom: "8px" },
          }}
        >
          <Box>
            <Typography
              gutterBottom
              component="div"
              sx={{ fontWeight: "bold", fontSize: "1rem", lineHeight: 1.5 }}
            >
              {item.name}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: "0.5rem",
                  height: "0.5rem",
                  backgroundColor: "green",
                  borderRadius: "50%",
                  marginRight: "0.5rem",
                }}
              />
              <Typography
                color="black"
                sx={{ fontSize: "0.875rem", lineHeight: 1.5 }}
              >
                Veg
              </Typography>
            </Box>
            <Typography
              gutterBottom
              component="div"
              sx={{ fontSize: "0.75rem", lineHeight: 1.5, mt: 1, mb: 2 }}
            >
              Made with whole milk and decadent coffee beans
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            sx={{
              borderRadius: "8px",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0.4rem 0.75rem",
              textTransform: "none",
            }}
          >
            Add +
          </Button>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
