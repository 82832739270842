import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openBillPrintModal } from "../../redux-store/features/cart/cartSlice";
import { setCurrentOrder } from "../../redux-store/features/orders/ordersSlice";
import { RootState } from "../../redux-store/store";
import DottedLine from "../Molecules/DottedLine";
import { axiosClient } from "../../api/axiosClient";

type PaymentMethod = "Cash" | "Card" | "UPI";

const PrintBillComponent = ({ handleCancel }: { handleCancel: () => void }) => {
  const [selectedPayment, setSelectedPayment] = useState<PaymentMethod | "">(
    "Cash"
  );
  const [percentage, setPercentage] = useState<string>("");

  //state
  const dispatch = useDispatch();
  const cartItems = useSelector((state: RootState) => state.cart.items);

  const handleSelectPayment = (method: PaymentMethod) => {
    setSelectedPayment(method);
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    setPercentage(event.target.value);
  };

  const discountPercentage = Number(percentage) / 100;
  const initialBillAmount = cartItems.reduce(
    (acc, val) => (acc += val.amount),
    0
  );
  const finalBillAmount =
    initialBillAmount - initialBillAmount * discountPercentage;

  const handlePrintBill = async () => {
    const payload = {
      bill: {
        items: cartItems.map((item, index) => ({
          productId: item.productCode,
          sno: index + 1,
          itemName: item.name,
          quantity: item.quantity,
          mrp: item.amount,
          amount: item.amount,
          quantityType: item.quantityType,
        })),
        tax: {
          // Define your tax logic here
        },
        discount: {
          isDiscountApplied: percentage !== "0",
          discountInPercentage: Number(percentage),
          discountAmount: initialBillAmount * (Number(percentage) / 100),
        },
        totalAmount: finalBillAmount,
        modeOfPayment: selectedPayment,
        billDate: new Date().toUTCString(),
        billNo: `${new Date().getFullYear()}${(new Date().getMonth() + 1).toString().padStart(2, "0")}${new Date().getDate().toString().padStart(2, "0")}${new Date().getHours().toString().padStart(2, "0")}${new Date().getMinutes().toString().padStart(2, "0")}${new Date().getSeconds().toString().padStart(2, "0")}${new Date().getMilliseconds().toString().padStart(3, "0")}`,
      },
    };

    try {
      // Create an instance of axiosClient
      const client = axiosClient;

      // Use the client to make the POST request
      const response = await client.post(
        "/v1/admin/private/api/create-bill",
        payload
      );

      if (response && response.data && response.data.isSuccess) {
        dispatch(setCurrentOrder(response.data.data));
      }
      dispatch(openBillPrintModal());
    } catch (error) {
      console.error("Error creating bill:", error);
    }
  };

  return (
    <div>
      <Typography
        textAlign="center"
        sx={{ fontSize: "22px", fontWeight: "bold" }}
      >
        BILL OF SUPPLY
      </Typography>
      <DottedLine />
      <Box>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Typography
            sx={{
              flex: 1,
              textAlign: "left",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            S.No
          </Typography>
          <Typography
            sx={{
              flex: 3,
              textAlign: "left",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            Item
          </Typography>
          <Typography
            sx={{
              flex: 2,
              textAlign: "left",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            Qty
          </Typography>
          <Typography
            sx={{
              flex: 2,
              textAlign: "left",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            MRP
          </Typography>
          <Typography
            sx={{
              flex: 2,
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            Amount
          </Typography>
        </Box>
        <DottedLine />
        {cartItems.map((item, index) => (
          <Box key={index} sx={{ display: "flex", width: "100%" }}>
            <Typography sx={{ flex: 1, textAlign: "left", fontSize: "12px" }}>
              {index + 1}
            </Typography>
            <Typography sx={{ flex: 3, textAlign: "left", fontSize: "12px" }}>
              {item.name}
            </Typography>
            <Typography sx={{ flex: 2, textAlign: "left", fontSize: "12px" }}>
              {item.quantity} {item.quantityType.toLowerCase().slice(0, 2)}
            </Typography>
            <Typography sx={{ flex: 2, textAlign: "left", fontSize: "12px" }}>
              {item.mrp}
            </Typography>
            <Typography sx={{ flex: 2, textAlign: "center", fontSize: "12px" }}>
              {item.amount}
            </Typography>
          </Box>
        ))}
      </Box>
      <DottedLine />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
          Grand Total {initialBillAmount}
        </Typography>
        {percentage === "0" || percentage === "" ? (
          ""
        ) : (
          <Typography sx={{ fontSize: "10px" }}>
            Discount {percentage}%
          </Typography>
        )}
      </Box>
      <Typography
        textAlign="center"
        sx={{ fontSize: "22px", fontWeight: "bold", mt: 1 }}
      >
        BILL AMOUNT: {finalBillAmount}
      </Typography>
      <div style={{ borderBottom: "2px solid black", marginTop: "5px" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          mt: 2,
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "10px",
            flex: 1, // Takes 50% of the space
            textAlign: "left", // Align the text to the left
          }}
        >
          Mode Of Payment:
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flex: 1, // Takes the remaining 50% of the space
            justifyContent: "space-evenly", // Distributes the buttons evenly
          }}
        >
          {(["Cash", "Card", "UPI"] as PaymentMethod[]).map((method) => (
            <Button
              key={method}
              variant={selectedPayment === method ? "contained" : "outlined"}
              onClick={() => handleSelectPayment(method)}
              sx={{
                minWidth: "60px",
                height: "35px",
                fontSize: "10px",
                mr: 1,
              }}
            >
              {method}
            </Button>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 1,
          my: 3,
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "10px",
            flex: 1, // Takes 50% of the space
            textAlign: "left", // Align the text to the left
          }}
        >
          Apply Discount:
        </Typography>
        <FormControl sx={{ minWidth: "120px", height: "35px" }}>
          {" "}
          {/* Adjust the FormControl size to match the button */}
          <InputLabel id="percentage-select-label" size="small">
            Discount
          </InputLabel>
          <Select
            labelId="percentage-select-label"
            id="percentage-select"
            value={percentage}
            label="Discount"
            onChange={handleChange}
            size="small" // Adjust the select size to better match the button height
            sx={{
              height: "35px", // Make sure the Select height matches the button
              ".MuiOutlinedInput-input": {
                paddingTop: "10px",
                paddingBottom: "10px",
              }, // Adjust padding to vertically center the text
              ".MuiSelect-select": { height: "auto" }, // Ensure the select height adjusts to padding correctly
            }}
          >
            {["", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"].map(
              (value) => (
                <MenuItem key={value} value={value}>
                  {value} {value === "" ? "None" : "%"}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: 2,
        }}
      >
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handlePrintBill}>
          Place Order
        </Button>
      </Box>
    </div>
  );
};

export default PrintBillComponent;
