import { useEffect, useState, useMemo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../redux-store/hooks";
import { getCategoryList, getProductsList } from "../services";

const useProductSearch = () => {
  const dispatch = useAppDispatch();
  const products = useAppSelector((state) => state.allproducts.data);
  const categories = useAppSelector((state) => state.categories.data);
  const [searchTxt, setSearchTxt] = useState("");
  const [category, setCateory] = useState("");

  // Fetch products list if not already fetched
  useEffect(() => {
    if (products.length === 0) {
      dispatch(getProductsList());
    }
    if (categories.length === 0) {
      dispatch(getCategoryList());
    }
  }, [dispatch, products.length, categories.length]);

  // Memoized filtered products based on searchTxt
  const filteredProducts = useMemo(() => {
    if (!searchTxt && !category) return products;

    if (!searchTxt && category) {
      return products.filter((product) => product.category.toLowerCase() === category);
    }

    const lowerCaseSearchTxt = searchTxt.toLowerCase();
    return products.filter(
      ({ name, category, productCode }) =>
        name.toLowerCase().includes(lowerCaseSearchTxt) ||
        category.toLowerCase().includes(lowerCaseSearchTxt) ||
        productCode.toString().includes(searchTxt)
    );
  }, [products, searchTxt, category]);

  // Debounced search function to optimize performance
  const searchTheProduct = useCallback((txt: string) => {
    setSearchTxt(txt);
  }, []);

  const setCategoryTxt = useCallback((txt: string) => {
    setCateory(txt.toLowerCase());
  }, []);

  return { filteredProducts, searchTxt, searchTheProduct, categories, setCategoryTxt };
};

export { useProductSearch };
