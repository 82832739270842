import axios from "axios";
import {  EMAIL_API_KEY, STORAGE_KEYS, URLS } from "../constants";

const token = localStorage.getItem(STORAGE_KEYS.TOKEN);

const axiosClient = axios.create({
  baseURL: URLS.BASE_URL,
  headers: {
    "Content-Type": "application/json",
    accept: 'application/json',
  },
});
const axiosEmailClient = axios.create({
  baseURL: URLS.EMAIL_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    accept: 'application/json',
    'api-key':EMAIL_API_KEY,
  },
});


export {axiosClient,axiosEmailClient};
