import * as React from 'react';
import { Box, Typography, Link, useTheme } from '@mui/material';
import { ICategoryType } from '../../../all-types';

interface CategoryCardProps {
    category: ICategoryType;
    selectedCategory: string;
    onClick: () => void;
}

const CategoryCard: React.FC<CategoryCardProps> = ({ category, onClick, selectedCategory }) => {
    const theme = useTheme();
    const isSelected = selectedCategory === category.categoryName;
    return (
        <Box
            onClick={onClick}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 2,
                width: "100%",
                border: '1px solid #ddd',
                cursor: 'pointer',
                backgroundColor: isSelected? theme.palette.primary.main: theme.palette.secondary.light,
                color: theme.palette.secondary.contrastText,
                '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText
                },
            }}

        >
            <Typography variant="button">
                {category?.categoryName}
            </Typography>
            <Link underline="none" onClick={onClick}>
                {' '}
            </Link>
        </Box>
    );
};

export { CategoryCard };