import { Box, Theme, useTheme } from '@mui/material';
import { FC } from 'react';
import { ICategoryType, ProductType } from '../../all-types';
import BillingComponent from '../../components/BillingComponent/BillingComponent';
import { STYLE_CONSTANTS } from '../../constants/styles';
import { CategoryPanel, ProductPanel } from './panels';

const PANEL_HEIGHTS = `calc(100vh - ${STYLE_CONSTANTS.HEADER_HEIGHT_IN_PX} -
   ${STYLE_CONSTANTS.DYNAMIC_TAB_HEIGHT_IN_PX} - ${STYLE_CONSTANTS.DYNAMIC_TAB_PADDING_IN_PX})`;

const styles = {
  billingScreen: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: PANEL_HEIGHTS,
    justifyContent: 'space-between',
    //backgroundColor: theme.palette.background.default,
  }),
  categoryPanel: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: STYLE_CONSTANTS.BILLING_PANEL_CATEGORY,
    height: PANEL_HEIGHTS,
    overflowY: 'auto',
    borderRight: `1px solid ${theme.palette.divider}`,
    scrollbarWidth: "thin"

  }),
  productPanel: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width:  STYLE_CONSTANTS.BILLING_PANEL_ITEMS,
    height: PANEL_HEIGHTS,
    overflowY: 'auto',
    borderRight: `1px solid ${theme.palette.divider}`,
    scrollbarWidth: "thin"
  }),
  rightPanel: (theme: Theme) => ({
    flex: 1,
    width: STYLE_CONSTANTS.BILLING_PANEL_CART,
    height: PANEL_HEIGHTS,
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'start',
    borderLeft: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  }),
};

interface IBillingScreenProps {
  products: ProductType[];
  categories: ICategoryType[];
  setCategoryTxt: (txt: string) => void;
}

const BillingScreen: FC<IBillingScreenProps> = ({ products, setCategoryTxt, categories }) => {
  const theme = useTheme();

  return (
    <Box sx={styles.billingScreen(theme)}>
      <Box sx={styles.categoryPanel(theme)}>
        <CategoryPanel setCategoryTxt={setCategoryTxt} categories={categories} />
      </Box>
      <Box sx={styles.productPanel(theme)}>
        <ProductPanel products={products} />
      </Box>
      <Box sx={styles.rightPanel(theme)}>
        <BillingComponent handleOpen={() => console.log("")} />
      </Box>
    </Box>
  );
};

export { BillingScreen };

