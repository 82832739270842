import { useCallback, useEffect, useMemo, useState } from "react";
import Handlebars from "handlebars";
import Stripo from "./Stripo";
import { attributes } from "./attributes/client01";
import { extractTemplateData } from "./appUtils";
import "./styles.css";
//@ts-ignore
import HTML_TEMPLATE from "./templates/kshinde-html.ejs";
//@ts-ignore
import CSS_TEMPLATE from "./templates/kshinde.ejs";



const templateName = "986102_Promo";

export default function LWStripPlugin() {
  const [html, setHtml] = useState<string>();
  const [css, setCss] = useState<string>();

  const data: object = useMemo(() => {
    return extractTemplateData(attributes.attributes);
  }, []);

  const render = useCallback(async () => {
    // const templateHtml = await Handlebars.compile(
    //   await readFile(`${HTML_TEMPLATE}`)
    // )(data);
    setHtml(HTML_TEMPLATE);
    // const templateCss = await Handlebars.compile(
    //   await readFile(`${CSS_TEMPLATE}`)
    // )(data);
    setCss(CSS_TEMPLATE);
  }, [data]);

  useEffect(() => {
    render();
  }, [render]);

  return (
    <div className="App">
      <Stripo
        pluginId="887ffbd949a34318b4d8ba0134c574b1"
        secretKey="2ab180b656d5493cbb8609becef944b4"
        html={html}
        css={css}
      />
    </div>
  );
}

async function readFile(file:string) {
  return await fetch(file).then(response => response.text());
}