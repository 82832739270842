import { RowData } from "./Interfaces";

export const metricOptions = [
  "KG",
  "GRAMS",
  "LITER",
  "MILLI-LITER",
  "SMALL",
  "MEDIUM",
  "LARGE",
  "ITEM",
  "SLICE",
  "PIECE",
];

export const metricAbbreviations = {
  KG: "kg",
  GRAMS: "gms",
  LITER: "li",
  "MILLI-LITER": "ml",
  SMALL: "sm",
  MEDIUM: "md",
  LARGE: "lg",
  ITEM: "it",
  SLICE: "sl",
  PIECE: "pi",
};

export const QUANTITY_TYPES = {
  KG: "KG", // kg
  GRAMS: "GRAMS", // gms
  LITER: "LITER",
  MILLI_LITER: "MILLI_LITER",
  SMALL: "SMALL",
  MEDIUM: "MEDIUM",
  LARGE: "LARGE",
  ITEM: "ITEM",
  SLICE: "SLICE",
  PIECE: "PIECE",
  DOZEN: "DOZEN",
};

export const initialRows: RowData[] = [
  { id: "1", mrp: "00", quantity: "0", metric: "KG" },
  // ...more initial rows if necessary
];

export const STATUS = {
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  EMPTY: "EMPTY",
};

export const STORAGE_KEYS = {
  TOKEN : "token",
  USER_ID: "user_id"
}
