import {
  Stack,
  CircularProgress,
  Typography,
  Button,
  Grid,
  TextField,
  Box,
  InputLabel,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CustomDropDown from "../../../../components/DropDownComponent/DropDownComponent";
import {
  IRequestContacts,
  IRequestEmail,
  IRequestTemplate,
} from "../../../../all-types";
import { axiosEmailClient } from "../../../../api";
import SendIcon from "@mui/icons-material/Send";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
interface AddPopupProps {
  exit?: () => void;
}

const MailBoxPopup: FC<AddPopupProps> = ({ exit }) => {
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState<Partial<IRequestTemplate>[]>([]);
  const [contacts, setContacts] = useState<Partial<IRequestContacts>[]>([]);
  const [form, setForm] = useState<Partial<IRequestEmail>>({
    to: [],
    isActive: true,
    subject: "",
    attachments: [],
    templateID: "",
    text: "",
    templateVariables:""
  });
  const [value, setValue] = useState('');


  const getContacts = async () => {
    try {
      const req = await axiosEmailClient.get("/masters/contact/get-contacts");
      const { data } = req.data;
      setContacts(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleQuillChange = (event:any)=>{
    setValue(event);
    console.log(event);
  }

  useEffect(() => {
    getContacts();
    getTemplates();
  }, []);

  if (loading) {
    return (
      <Stack
        sx={{
          height: "80vh",
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress size={100} />
      </Stack>
    );
  }

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSave = async () => {

    const template = templates.find((template) => template._id === form.templateID);
    if (template?.type === 'dynamic') {
      form.templateVariables = {
        logo:"https://avatars.githubusercontent.com/u/47146745?v=4",
        phone:"9564556",
        email:"tarunchelumalla@gmail.com",
        subTotal:"1500",
        tax:"1",
        total:"30000",
        orders:[
        {
          orderImage:"https://firebasestorage.googleapis.com/v0/b/bill-desk-fb37f.appspot.com/o/files%2FDSC06963.jpg?alt=media&token=5246da7b-c3d8-46dd-b6e2-728bf6592ae9",
          qty:"2",
          name:"testIdli",
          description:"Idli is best",
          price:"500"
        },
        {
          orderImage:"https://firebasestorage.googleapis.com/v0/b/bill-desk-fb37f.appspot.com/o/files%2FDSC06510.jpg?alt=media&token=75ec2b09-d9de-49fb-8cfd-47516b4f944d",
          qty:"2",
          name:"Samosa",
          description:"Test Description",
          price:"500"
        },
        {
          orderImage:"https://firebasestorage.googleapis.com/v0/b/bill-desk-fb37f.appspot.com/o/files%2FDSC06673.jpg?alt=media&token=bd8f65db-0457-4900-9cf5-2f98c31569e6",
          name:"Vada",
          description:"vada is simple",
          qty:"2",
          price:"500"
        },
        ],
        receiptUrl:"https://buffer.com/library/content/images/size/w1200/2023/10/free-images.jpg"
      }
    }
    try {
      const req = await axiosEmailClient.post("/mail/send", form);
      console.log(req.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getTemplates = async () => {
    try {
      const req = await axiosEmailClient.get("/masters/template/get-template");
      const { data } = req.data;

      setTemplates(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeOption = (event: any, name: string) => {
    setForm({
      ...form,
      [name]: event,
    });
  };



  return (
    <Stack component="form" gap={2} p={2}>
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
          <Typography variant="h5">Send Mail</Typography>
        </Stack>
        <Button
          variant="contained"
          onClick={onSave}
          startIcon={<SendIcon/>}
          style={{ height: 50, width: 100 }}
        >
          Send
        </Button>
      </Stack>
      <Box
        sx={{
          padding: "30px",
          border: "1px solid black",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={6} alignSelf={"center"}>
            <CustomDropDown
             placeholder="Select Contacts"
              key={"cutomDrop"}
              bindLabel={"name"}
              bindValue={"id"}
              onChange={handleChangeOption}
              options={contacts}
              name="to"
              isMultiple={true}
              selectedValues={[]}
              label="Contacts"
            />
          </Grid>
          <Grid item xs={6} alignSelf={"center"}>
            <CustomDropDown
              placeholder="Select template"
              key={"templateDropDown"}
              bindLabel={"templateName"}
              bindValue={"_id"}
              name="templateID"
              onChange={handleChangeOption}
              options={templates}
              isMultiple={false}
              selectedValues={[]}
              label="Templates"
            />
          </Grid>

        </Grid>
        <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={6} alignSelf={"center"}>
            <TextField
              sx={{ borderRadius: "6px", marginTop: "6px" }}
              variant="outlined"
              onChange={handleChange}
              fullWidth
              name="subject"
              required
              value={form.subject}
              label="Subject"
              placeholder="Enter subject"
            ></TextField>
          </Grid>

        </Grid>

        <Grid item xs={12}>
        <InputLabel id="mutiple-select-label" sx={{marginBottom:'6px'}}>Content</InputLabel>
        <ReactQuill theme="snow" value={value} onChange={handleQuillChange} />
        </Grid>
        {/* <Grid item xs={4} alignSelf={"center"} justifyContent={"flex-end"}>
          <Button variant="contained" onClick={onSave}>
            Save
          </Button>
        </Grid> */}
      </Box>
    </Stack>
  );
};

export { MailBoxPopup };
