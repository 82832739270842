import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosClient } from "../api";
import { URLS } from "../constants";
import { isResponseSuccess } from "../utils";
import { ProductType } from "../all-types";

export const getProductsList = createAsyncThunk(
  "products/fetchProducts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(
        `${URLS.GET_PRODUCTS_LIST}?limit=10000`
      );
      if(!isResponseSuccess(response.status)) {
        throw new Error("Failed to get products.");
      }
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "products/deleteProduct",
  async (productId:string, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosClient.post(`${URLS.DELETE_PORODUCT}/${productId}`);
      if (!isResponseSuccess(response?.status)) {
        throw new Error(response.statusText);
      }
      dispatch(getProductsList());
      return response;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || "Could not delete the product"
      );
    }
  }
);

export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async (p:ProductType, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosClient.post(`${URLS.UPADTE_PRODUCT}/${p?._id}`, {product: p});
      if (!isResponseSuccess(response?.status)) {
        throw new Error(response.statusText);
      }
      dispatch(getProductsList());
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || "Could not delete the product"
      );
    }
  }
);

export const createProduct = createAsyncThunk(
  "products/createProduct",
  async (p:ProductType, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosClient.post(`${URLS.CREATE_PRODUCT}`, {product: p});
      if (!isResponseSuccess(response?.status)) {
        throw new Error(response.statusText);
      }
      dispatch(getProductsList());
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || "Could not delete the product"
      );
    }
  }
);
