/**
 * AUTHOR: codewithshinde
 * Description:
 * references:
 * https://developer.mozilla.org/en-US/docs/Web/API/WebUSB_API
 * https://hackernoon.com/exploring-the-webusb-api-connecting-to-usb-devices-and-printing-with-tspltspl2
 * https://www.sabatino.dev/webusb-is-my-favorite-browser-api/
 */

class LightUSB {
  productId: number;
  vendorId: number;
  device: any | null = null;

  constructor(productId: number, vendorId: number) {
    this.productId = productId;
    this.vendorId = vendorId;
  }

  /**
   * Requests a USB device matching the specified productId and vendorId.
   */
  getDevice = async () => {
    try {
      if (!this.device) {
        // @ts-ignore
        return await navigator.usb.requestDevice({
          filters: [{ vendorId: this.vendorId, productId: this.productId }],
        });
      }
      return this.device;
    } catch (e) {
      console.error("Failed to request device:", e);
      return null;
    }
  };

    /**
   * Checks if the USB device with the specified vendorId and productId is already paired/connected.
   */
  async isPaired(): Promise<boolean> {
    // @ts-ignore
    const devices = await navigator.usb.getDevices();
    this.device = devices.find(
      (device:any) => device.vendorId === this.vendorId && device.productId === this.productId
    ) || null;

    return !!this.device;
  }


  /**
   * Retrieves all connected USB devices.
   */
  static getUsbDevices = async () => {
    // @ts-ignore
    return await navigator.usb.getDevices();
  };

  /**
   * Initiates the LightUSB instance by prompting the user to select a device.
   */
  static initiateBySelect = async () => {
    try {
      // @ts-ignore
      const device = await navigator?.usb.requestDevice({ filters: [] });
      if (!device) {
        console.error("No device selected");
      }
      const lusb = new LightUSB(device?.productId, device?.vendorId);
      await lusb.applyDeviceSettings(device);
      return lusb;
    } catch (e) {
      console.error("Failed to initiate by select:", e);
      return null;
    }
  };

  /**
   * Applies device settings such as opening the device and claiming interfaces.
   */
  applyDeviceSettings = async (device = this.device) => {
    if (!device) {
      throw new Error("No device found");
    }
    this.device = device;
    if (!this.isDeviceOpen()) {
      await this.device.open();
    }
    await this.claimInterfaces();
  };

  /**
   * Claims all available interfaces of the device.
   */
  private claimInterfaces = async () => {
    if (!this.device) return;
    for (const config of this.device.configurations) {
      for (const iface of config.interfaces) {
        if (!iface.claimed) {
          await this.device.claimInterface(iface.interfaceNumber);
        }
      }
    }
    await this.device.selectConfiguration(1);
    await this.device.claimInterface(0);
  };

  /**
   * Connects to the USB device.
   */
  connect = async () => {
    try {
      if (!this.isBrowserSupported()) {
        throw new Error("WebUSB API is not supported!");
      }
      const device = await this.getDevice();
      await this.applyDeviceSettings(device);
      return { status: true };
    } catch (e) {
      return {
        error: e,
        errorMessage: "Connection Error, Please contact Admin",
        status: false,
      };
    }
  };

  /**
   * Prints the provided data to the connected USB device.
   */
  print = async (data: Uint8Array): Promise<void> => {
    this.device = await this.getDevice();
    if (!this.device) {
      throw new Error("No device connected");
    }
    // Ensure the device is open before printing
    if (!this.isDeviceOpen()) {
      await this.applyDeviceSettings();
    }
    const endpoint =
      this.device.configuration.interfaces[0].alternates[0].endpoints.find(
        (endpoint: any) => endpoint.direction === "out"
      );
    if (!endpoint) {
      throw new Error("No valid endpoint found for printing");
    }
    await this.device.transferOut(endpoint.endpointNumber, data);
  };

  private isDeviceOpen = () => {
    return this.device && this.device.opened;
  };

  private isBrowserSupported = () => {
    return "usb" in navigator;
  };

  disconnect = async (): Promise<void> => {
    if (this.device && this.device.opened) {
      await this.device.close(); // Close the device when disconnecting
    }
  };
}

export { LightUSB };
