import { createTheme, Theme } from "@mui/material";
import { scrollBarStyles } from "./scroll-bar";

// Extend the Palette interface to include custom colors
declare module "@mui/material/styles" {
  interface Palette {
    color1: Palette["primary"];
    color2: Palette["primary"];
    color3: Palette["primary"];
    color4: Palette["primary"];
    color5: Palette["primary"];
    color6: Palette["primary"];
    color7: Palette["primary"];
    color8: Palette["primary"];
    color9: Palette["primary"];
    color10: Palette["primary"];
    color11: Palette["primary"];
    color12: Palette["primary"];
    color13: Palette["primary"];
    color14: Palette["primary"];
    color15: Palette["primary"];
    CommonWhite: Palette["primary"];
    CommonBlack: Palette["primary"];
  }

  interface PaletteOptions {
    color1?: PaletteOptions["primary"];
    color2?: PaletteOptions["primary"];
    color3?: PaletteOptions["primary"];
    color4?: PaletteOptions["primary"];
    color5?: PaletteOptions["primary"];
    color6?: PaletteOptions["primary"];
    color7?: PaletteOptions["primary"];
    color8?: PaletteOptions["primary"];
    color9?: PaletteOptions["primary"];
    color10?: PaletteOptions["primary"];
    color11?: PaletteOptions["primary"];
    color12?: PaletteOptions["primary"];
    color13?: PaletteOptions["primary"];
    color14?: PaletteOptions["primary"];
    color15?: PaletteOptions["primary"];
    CommonWhite: PaletteOptions["primary"];
    CommonBlack: PaletteOptions["primary"];
  }
}

// Define the theme configuration
export const theme: Theme = createTheme({
  typography:{
    fontFamily: ["Lato"].join(",")
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          ...scrollBarStyles
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '&.MuiTableCell-root.MuiTableCell-head': {
            backgroundColor: "#5C6BC0",
            color: "white",
          },
        },
      },
    },
  },
  palette: {
    color1: { main: "#7A543B" },
    color2: { main: "#896045" },
    color3: { main: "#A57C61" },
    color4: { main: "#A57C61" }, // Same as Color 3
    color5: { main: "#F6EFEB" },
    color6: { main: "#ECE0D8" },
    color7: { main: "#A57C61" }, // Same as Color 3 and Color 4
    color8: { main: "#6B6B6B" },
    color9: { main: "#E6D4BA" },
    color10: { main: "#A69D98" },
    color11: { main: "#8B8785" },
    color12: { main: "#ffffff" }, // White
    color13: { main: "#FBF7F2" },
    color14: { main: "#F6F6F6" },
    color15: { main: "#A47F66" },
    CommonWhite: { main: "#fff" },
    CommonBlack: { main: "#000" },
    primary: { 
      main: "rgb(63, 81, 181)",  // Deep blue
      contrastText: "#fff"        // White for contrast
    },
    secondary: { 
      main: "#5C6BC0"            // Lighter blue
    },
    info: { 
      main: "#42A5F5",           // Bright blue for information
      contrastText: "#fff"       // White for contrast
    },
    error: { 
      main: "rgb(199, 33, 40)",  // Keeping the red for error states
      contrastText: "#fff"       // White for contrast
    },
    success: { 
      main: "rgb(56, 142, 60)",           // Blue-green for success
      contrastText: "#fff"       // White for contrast
    },
    warning: { 
      main: "#fbc02d",           // Yellow-orange for warnings
      contrastText: "#000"       // Black for contrast
    },
    background: {
      default: "rgb(245, 245, 255)",  // Very light blue for the background
      paper: "rgb(255, 255, 255)"     // White for paper elements
    },
    action: {
      hover: "rgb(197, 202, 233)"    // Soft blue for hover states
    },
  },
});
