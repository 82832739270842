export enum QUANTITY_TYPES {
    KG = "KG",       // kg
    GRAMS = "GRAMS", // gms
    LITER = "LITER",  
    MILLI_LITER = "MILLI-LITER",
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    LARGE = "LARGE",
    ITEM = "ITEM",
    SLICE = "SLICE",
    PIECE = "PIECE",
    DOZEN = "DOZEN"
  }