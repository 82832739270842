import { Box, Grid, Modal } from '@mui/material';
import * as React from 'react';
import { ProductType } from '../../../all-types';
import { ProductPopup } from '../components';
import ProductCard from '../components/product-card';

interface IProductPanelProps {
    products: ProductType[]
}

const ProductPanel: React.FC<IProductPanelProps> = ({ products }) => {
    const [selectedProduct, setSelectedProduct] = React.useState<ProductType | null>(null);
    const [open, setOpen] = React.useState(false);
    
    const handleOpen = (p: ProductType) => {
        setSelectedProduct(p);
        setOpen(true)
    };
    const handleClose = () => setOpen(false);

    const popupStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        boxShadow: 24,
    };

    return (
        <>
            <Grid container spacing={1} p={0.5}>
                {products?.map((product) => (
                    <Grid item key={product.productId} xs={12} sm={12} md={6} lg={3}
                        display={"flex"} justifyContent={"center"}>
                        <ProductCard item={product} handleOpen={handleOpen} />
                    </Grid>
                ))}
            </Grid>
            <Modal open={open} onClose={handleClose}>
                <Box sx={{ ...popupStyle }}>
                    {selectedProduct && (
                        <ProductPopup product={selectedProduct} />
                    )}
                </Box>
            </Modal>
        </>
    );
};

export { ProductPanel };

