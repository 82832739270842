import React, { useEffect } from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import CartComponent from "./CartComponent";
import PrintBillComponent from "./PrintBillComponent";
import { useSelector } from "react-redux";
import { RootState } from "../../redux-store/store";

import { useTheme } from "@mui/material/styles";

export interface CartItem {
  id: string;
  image: string; // URL to the product image
  name: string;
  productCode: string;
  quantity: number;
  amount: number;
  quantityType: string;
  rateCardId: string;
  noOfQuantity?: number;
  quantityTypeShort?: string;
  mrp: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface BillingComponentProps {
  handleOpen: () => void;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && (
        <Box sx={{ p: 3, height: "100%" }}>
          <Typography sx={{ height: "100%" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BillingComponent: React.FC<BillingComponentProps> = ({ handleOpen }) => {
  const [value, setValue] = React.useState(0);
  const cartItems = useSelector((state: RootState) => state.cart.items);

  const theme = useTheme();

  useEffect(() => {
    if (cartItems.length === 0) {
      setValue(0);
    }
  }, [cartItems]);

  return (
    <Box
      sx={{
        width: "380px",
        borderLeft: 1,
        borderColor: theme.palette.color8.main,
        backgroundColor: theme.palette.color12.main,
        height: `calc(100vh - 64px)`,
      }}
    >
      {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} aria-label="basic tabs example">
          <Tab label="Cart" {...a11yProps(0)} />
          <Tab label="Billing" {...a11yProps(1)} />
        </Tabs>
      </Box> */}
      <CustomTabPanel value={value} index={0}>
        <CartComponent changeTab={() => setValue(1)} handleOpen={handleOpen} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div className="printable">
          <PrintBillComponent handleCancel={() => setValue(0)} />
        </div>
      </CustomTabPanel>
    </Box>
  );
};

export default BillingComponent;
