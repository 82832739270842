import * as React from 'react';
import { useState } from 'react';
import { Grid, TextField, Button, Typography, Box, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Stack, useTheme } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useAppSelector } from '../../redux-store/hooks';
import { LoadingButton } from '@mui/lab';

interface ClientInfoType {
    clientID: string;
    clientName: string;
    clientLogo: string;
    email: string;
    PhoneNumber: number;
    gstNo: string;
    fssaiNo: string;
    address: {
        shopNo: string;
        buildingName: string;
        area: string;
        city: string;
        state: string;
        landmark: string;
        fullAddress: string;
        country: string;
        pincode: number;
        coords: {
            latitude: number;
            longitude: number;
        };
    };
    printerSettings: {
        name: string;
        productId: string;
        vendorId: string;
        connectionType: string;
        driverInfo: string;
        printerLocation: string;
        isPrinterConfigured: boolean;
        connectionSettings: {
            productId: string;
            vendorId: string;
            remoteHostIp: string;
            remoteHostPort: string;
        };
    };
    cardReaderSettings: {
        paytm: {
            mid: string;
            tid: string;
            channelId: string;
        };
    };
}

const ClientInfo: React.FC = () => {
    const theme = useTheme();
    const info = useAppSelector(state => state.clientInfo.data);
    const [clientInfo, setClientInfo] = useState<ClientInfoType>(info);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setClientInfo((prevClientInfo) => ({ ...prevClientInfo, [name]: value }));
    };

    const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => {
        setClientInfo((prevClientInfo) => ({
            ...prevClientInfo,
            address: { ...prevClientInfo.address, [field]: event.target.value },
        }));
    };

    const handlePrinterSettingsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => {
        setClientInfo((prevClientInfo) => ({
            ...prevClientInfo,
            printerSettings: { ...prevClientInfo.printerSettings, [field]: event.target.value },
        }));
    };

    const handleCardReaderSettingsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => {
        setClientInfo((prevClientInfo) => ({
            ...prevClientInfo,
            cardReaderSettings: { ...prevClientInfo.cardReaderSettings, paytm: { ...prevClientInfo.cardReaderSettings.paytm, [field]: event.target.value } },
        }));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Call API to save client info
        console.log(clientInfo);
    };

    return (
        <Box sx={{ maxWidth: 800, p: 2 }}>
            <Typography variant="h5" gutterBottom>
                Client Info
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Stack height={230}
                            sx={{
                                backgroundImage: `url(${clientInfo?.clientLogo})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                borderRadius: 2
                            }}
                            bgcolor={theme.palette.background.default}
                            justifyContent={"flex-end"}>
                            <LoadingButton
                                component="label"
                                color='primary'
                                variant='contained'
                                //loading={imgLoading}
                                loadingIndicator="Uploading…"
                                sx={{ height: 50 }}
                                startIcon={<CloudUploadIcon />}

                            >
                                {clientInfo?.clientLogo ? "Change Image" : "Upload Image"}
                                <input
                                    type="file"
                                    hidden
                                    accept="image/*"
                                //onChange={uploadImageToBucket}
                                />
                            </LoadingButton>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <Stack gap={2}>
                            <TextField
                                required
                                id="clientID"
                                name="clientID"
                                label="Client ID"
                                value={clientInfo.clientID}
                                onChange={handleInputChange}
                                fullWidth
                            />
                            <TextField
                                id="clientLogo"
                                name="clientLogo"
                                label="Client Name"
                                value={clientInfo.clientName}
                                onChange={handleInputChange}
                                fullWidth
                            />
                            <TextField
                                id="PhoneNumber"
                                name="PhoneNumber"
                                label="Phone Number"
                                value={clientInfo.PhoneNumber}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        <TextField
                            id="email"
                            name="email"
                            label="Email"
                            value={clientInfo.email}
                            onChange={handleInputChange}
                            fullWidth
                        />
                        </Stack>
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                        <TextField
                            id="email"
                            name="email"
                            label="Email"
                            value={clientInfo.email}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid> */}
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                          Shop Info
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="gstNo"
                            name="gstNo"
                            label="GST No"
                            value={clientInfo.gstNo}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="fssaiNo"
                            name="fssaiNo"
                            label="FSSAI No"
                            value={clientInfo.fssaiNo}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            Address
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="shopNo"
                            name="shopNo"
                            label="Shop No"
                            value={clientInfo.address.shopNo}
                            onChange={(event) => handleAddressChange(event, 'shopNo')}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="buildingName"
                            name="buildingName"
                            label="Building Name"
                            value={clientInfo.address.buildingName}
                            onChange={(event) => handleAddressChange(event, 'buildingName')}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="area"
                            name="area"
                            label="Area"
                            value={clientInfo.address.area}
                            onChange={(event) => handleAddressChange(event, 'area')}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="city"
                            name="city"
                            label="City"
                            value={clientInfo.address.city}
                            onChange={(event) => handleAddressChange(event, 'city')}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="state"
                            name="state"
                            label="State"
                            value={clientInfo.address.state}
                            onChange={(event) => handleAddressChange(event, 'state')}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="landmark"
                            name="landmark"
                            label="Landmark"
                            value={clientInfo.address.landmark}
                            onChange={(event) => handleAddressChange(event, 'landmark')}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="fullAddress"
                            name="fullAddress"
                            label="Full Address"
                            value={clientInfo.address.fullAddress}
                            onChange={(event) => handleAddressChange(event, 'fullAddress')}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="pincode"
                            name="pincode"
                            label="Pincode"
                            value={clientInfo.address.pincode}
                            onChange={(event) => handleAddressChange(event, 'pincode')}
                            fullWidth
                        />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            Printer Settings
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="printerName"
                            name="name"
                            label="Printer Name"
                            value={clientInfo.printerSettings.name}
                            onChange={(event) => handlePrinterSettingsChange(event, 'name')}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="productId"
                            name="productId"
                            label="Product ID"
                            value={clientInfo.printerSettings.productId}
                            onChange={(event) => handlePrinterSettingsChange(event, 'productId')}
                            fullWidth
                        />
                    </Grid> */}
                    {/* <Grid item xs={12} sm={6}>
                        <TextField
                            id="vendorId"
                            name="vendorId"
                            label="Vendor ID"
                            value={clientInfo.printerSettings.vendorId}
                            onChange={(event) => handlePrinterSettingsChange(event, 'vendorId')}
                            fullWidth
                        />
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="connectionType-label">Connection Type</InputLabel>
                                <Select
                                    labelId="connectionType-label"
                                    id="connectionType"
                                    name="connectionType"
                                    value={clientInfo.printerSettings.connectionType}
                                    //onChange={(event) => handlePrinterSettingsChange(event, 'connectionType')}
                                >
                                    <MenuItem value="USB">USB</MenuItem>
                                    <MenuItem value="WIFI">WIFI</MenuItem>
                                    <MenuItem value="BLUETOOTH">BLUETOOTH</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="driverInfo"
                                name="driverInfo"
                                label="Driver Info"
                                value={clientInfo.printerSettings.driverInfo}
                                onChange={(event) => handlePrinterSettingsChange(event, 'driverInfo')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="printerLocation"
                                name="printerLocation"
                                label="Printer Location"
                                value={clientInfo.printerSettings.printerLocation}
                                onChange={(event) => handlePrinterSettingsChange(event, 'printerLocation')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={clientInfo.printerSettings.isPrinterConfigured}
                                        onChange={(event) => handlePrinterSettingsChange(event, 'isPrinterConfigured')}
                                        name="isPrinterConfigured"
                                    />
                                }
                                label="Is Printer Configured"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                                Card Reader Settings
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="mid"
                                name="mid"
                                label="MID"
                                value={clientInfo.cardReaderSettings.paytm.mid}
                                onChange={(event) => handleCardReaderSettingsChange(event, 'mid')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="tid"
                                name="tid"
                                label="TID"
                                value={clientInfo.cardReaderSettings.paytm.tid}
                                onChange={(event) => handleCardReaderSettingsChange(event, 'tid')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="channelId"
                                name="channelId"
                                label="Channel ID"
                                value={clientInfo.cardReaderSettings.paytm.channelId}
                                onChange={(event) => handleCardReaderSettingsChange(event, 'channelId')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary">
                                Save
                            </Button>
                        </Grid>
                    </Grid> */}
                </Grid>
            </form>
        </Box>
    );
};

export { ClientInfo };