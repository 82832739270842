import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import React, { useState } from 'react';
import { IBillItem, IProductRate, ProductType, QUANTITY_TYPES, RateCard } from '../../../all-types';


interface ProductPopsProps {
    product: ProductType;
}

const ProductPopup: React.FC<ProductPopsProps> = ({ product }) => {
    const theme = useTheme();
    const [billRecord, setBillRecord] = useState<IBillItem>({
        productId: product.productId,
        sno: 0,
        itemName: product.name,
        quantity: 0,
        mrp: 0,
        amount: 0,
        noOfItems: 1,
        quantityType: "",
        quantityTypeShort: "",
        defaultQuantityType: "", // ADD ONLY ON CUSTOM
        defaultPrice: 0, // ADD ONLY ON CUSTOM
    });

    const [rateCardId, setSetRateCardId] = useState("");

    const onSelectOfRateCard = (rc: RateCard) => {
        setBillRecord({
            ...billRecord,
            mrp: rc.price,
            noOfItems: 1,
            amount: rc.price,
            quantity: rc.quantity,
            quantityType: rc.quantityType
        });
        setSetRateCardId(rc.name); //KS:CHANGE
    }

    const onSelectOfCustomRateCard = () => {
        setBillRecord({
            ...billRecord,
            mrp: 0,
            noOfItems: 1,
            amount: 0,
            quantity: 0,
            quantityType: ""
        });
        setSetRateCardId("CUSTOM"); //KS:CHANGE
    }

    const getCustomPrice = (quantity = billRecord.quantity, type = billRecord.quantityType) => {
        if (type) {

        }
        return billRecord.amount;
    }

    const setCustomType = (type: string) => {
        console.log(type, "???");
        setBillRecord({
            ...billRecord,
            amount: getCustomPrice(billRecord.quantity, type),
            quantityType: type
        });
    }

    const setCustomQuantity = (quantity: number) => {
        setBillRecord({
            ...billRecord,
            amount: getCustomPrice(quantity, billRecord.quantityType),
            quantity: quantity
        });
    }

    const incrementItem = () => {
        const newAmount = (billRecord.noOfItems + 1) * (billRecord.mrp);
        setBillRecord({
            ...billRecord,
            amount: newAmount,
            noOfItems: billRecord.noOfItems + 1
        });
    }

    const decrementItem = () => {
        const newAmount = (billRecord.noOfItems - 1) * (billRecord.mrp);
        setBillRecord({
            ...billRecord,
            amount: newAmount,
            noOfItems: billRecord.noOfItems - 1
        });
    }

    const isDefaultRatesExists = Object.values(product.defaults) && Object.values(product.defaults).length > 0;

    return (
        <Card sx={{
            display: 'flex', flexDirection: 'column', borderRadius: 4,
            p: 1, backgroundColor: theme.palette.CommonWhite.main
        }}>
            <Stack flexDirection={"row"}>
                <Stack p={0.5}>
                    <Box
                        component="img"
                        height={150}
                        width={150}
                        src={product.image}
                        alt={product.name}
                        style={{ borderRadius: 8 }}
                    />
                </Stack>
                <Stack p={0.5} height={165} display={"flex"} justifyContent={"space-between"}>
                    <Box>
                        <Typography component="div" variant="h5">
                            {product.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {product.description}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body2" color="text.secondary" flexDirection={"row"}>
                            <Typography component={"span"} variant="body2" fontWeight={"bold"}>Product Id: </Typography>
                            {product.productCode}
                        </Typography>
                        {/* <Typography variant="h6">MRP ₹300</Typography> */}
                    </Box>
                    <Grid item xs={6} display={"flex"} alignItems={"flex-end"} >
                        <Stack direction="row" gap={2} alignItems={"center"}  >
                            <Button variant='outlined' sx={{ fontSize: 25, borderRadius: 15 }}
                                disabled={billRecord.noOfItems === 1}
                                onClick={decrementItem}>-</Button>
                            <Typography variant="h6">{billRecord.noOfItems}</Typography>
                            <Button variant='contained' sx={{ fontSize: 25, borderRadius: 15 }} onClick={incrementItem}>+</Button>
                        </Stack>
                    </Grid>
                </Stack>
            </Stack>
            <CardContent >
                <Typography variant="body1">
                    {"Select Quantity"}
                </Typography>
                <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"}>
                    {Object.values(product.rateCard) && Object.values(product.rateCard)?.map((i: RateCard) => (
                        <Button key={i.name} variant={rateCardId === i.name ? "contained" : "outlined"}
                            sx={{ height: 50 }} onClick={() => onSelectOfRateCard(i)}>{i.name}</Button>
                    ))}
                    {isDefaultRatesExists &&
                        <Button key={"custom-btn"} variant={rateCardId === "CUSTOM" ? "contained" : "outlined"}
                            onClick={onSelectOfCustomRateCard} sx={{ height: 50 }}>Custom</Button>
                    }
                </Stack>

                <Grid container spacing={2} sx={{ mt: 1 }} justifyContent={"center"}>
                    {rateCardId === "CUSTOM" && (
                        <Grid item xs={8}>
                            <Typography variant="body1">Customize</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', border: '1px solid #ccc', borderRadius: 2 }}>
                                <TextField
                                    value={billRecord.quantity ? billRecord.quantity  : ""}
                                    onChange={(e) => setCustomQuantity(Number(e.target.value)) }
                                    //inputProps={{ style: { textAlign: 'center'} }}
                                    placeholder='ex: 125'
                                    sx={{
                                        border: 'none', outline: 'none',
                                        borderTopRightRadius: 0, borderBottomRightRadius: 0
                                    }}
                                    variant="outlined"
                                />
                                <Select
                                    value={billRecord.quantityType}
                                    onChange={(e) => setCustomType(e.target.value)}
                                    sx={{
                                        border: 'none', outline: 'none', width: '80%',
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        backgroundColor: theme.palette.primary.main,
                                        color: "white"
                                    }}
                                    variant="outlined">
                                    {Object.values(product.defaults)?.map((unit: RateCard) => (
                                        <MenuItem key={unit.name} value={unit.quantityType}>
                                            {unit.quantityType}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Grid>
                    )}
                    {/* <Grid item xs={6} display={"flex"} alignItems={"flex-end"} >
                        <Stack direction="row" gap={2} alignItems={"center"}  >
                            <Button variant='outlined' sx={{ fontSize: 25, borderRadius: 15 }}
                                disabled={billRecord.noOfItems === 1}
                                onClick={decrementItem}>-</Button>
                            <Typography variant="h6">{billRecord.noOfItems}</Typography>
                            <Button variant='contained' sx={{ fontSize: 25, borderRadius: 15 }} onClick={incrementItem}>+</Button>
                        </Stack>
                    </Grid> */}
                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                        <Stack flexDirection={"row"} gap={2}>
                            <Typography variant="h6" fontWeight={"bold"} color={theme.palette.primary.main}>{billRecord.noOfItems}x</Typography>
                            <Typography variant="h6" fontWeight={"bold"} color={theme.palette.primary.main}>Qty</Typography>
                            <Typography variant="h6" fontWeight={"bold"}>{billRecord.quantity} {billRecord.quantityType}</Typography>
                            <Typography variant="h6" fontWeight={"bold"} color={theme.palette.primary.main}>PRICE</Typography>
                            <Typography variant="h6" fontWeight={"bold"}>₹{billRecord.amount}</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2, gap: 2 }}>
                <Button variant="outlined" color="error" sx={{ fontSize: 25 }}>
                    Cancel
                </Button>
                <Button variant="contained" sx={{ fontSize: 25 }}>Done</Button>
            </Box>
        </Card>
    );
};

export { ProductPopup };

