import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { IRequestContacts } from "../../../../all-types/business";
import { axiosEmailClient } from "../../../../api";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

function ContactComponent() {
  const [rows, setRows] = useState([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<string>("");
  const [warningDialog, setWarningDialog] = useState<boolean>(false);

  const [contactForm, setContactForm] = useState<Partial<IRequestContacts>>({
    name: "",
    email: "",
  });

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", minWidth: 90,flex: 1,},
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      // editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      minWidth: 150,
      flex: 1,
    },
        {
      field: "action",
      headerName: "Action",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            <div className="center-container">
              <Grid spacing={3}>
                <EditIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleEdit(params.row)}
                />
                <DeleteIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() =>{ 
                     setWarningDialog(true)
                     setCurrentId(params.row._id as string)
                } }
                />
              </Grid>
            </div>
          </>
        );
      },
      flex: 1,
    },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setContactForm({
      ...contactForm,
      [name]: value,
    });
  };

  const fetchData = async () => {
    try {
      const req = await axiosEmailClient.get("/masters/contact/get-contacts");
      const { data } = req.data;

      setRows(data);
    } catch (err) {
      console.log(err);
    }
  };

  const onSave = async () => {
    try {

      if(currentId){
        contactForm.id = currentId;
      }
      console.log(contactForm,currentId)
      const req = await axiosEmailClient.post("/masters/contact", contactForm);

      setContactForm({
        name: "",
        email: "",
      });
      setDialogOpen(false);

      fetchData();
    } catch (err) {
      console.log(err);
    }
  };

  const handleEdit = async (row: any) => {
    console.log(row);

    setContactForm(() => ({
      name:row.name,
      email: row.email,
    }));
    setCurrentId(row.id);
    setDialogOpen(true);
  };

  const handleDelete = async () => {
    console.log(currentId);
    try {
      const req = await axiosEmailClient.delete(`/masters/contact/${currentId}`);
 
      fetchData();
    } catch (err) {
      console.error(err);
    }
    setWarningDialog(false)
  };

  const onAddContact = ()=>{
    setCurrentId("")
    setContactForm({
      name: "",
      email: "",
    });
    setDialogOpen(true)
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Box
        sx={{ height: "calc(100vh - 214px)", width: "100%", padding: "20px" }}
      >
        <Box display={"flex"} flexDirection={"row"} justifyContent={"end"}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            width={"100%"}
            justifyContent={"space-between"}
          >
            <Typography variant="h6" fontWeight={"400"}>
              Contacts
            </Typography>
            <Button
              variant="outlined"
              startIcon={<Add />}
              onClick={() =>onAddContact() }
            >
              {" "}
              Add Item
            </Button>
          </Box>
        </Box>
        <DataGrid
          sx={{ marginTop: "8px" }}
          rows={rows}
          columns={columns}
          getRowId={(row: any) => row.id}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
             pageSizeOptions={[5,10,50]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Contacts</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              padding: "30px",
              border: "1px solid black",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4} alignSelf={"center"}>
                <TextField
                  sx={{ borderRadius: "50px" }}
                  variant="outlined"
                  value={contactForm.name}
                  onChange={handleChange}
                  fullWidth
                  name="name"
                  label="Name"
                  placeholder="Enter Name"
                ></TextField>
              </Grid>
              <Grid item xs={4} alignSelf={"center"}>
                <TextField
                  sx={{ borderRadius: "6px" }}
                  variant="outlined"
                  onChange={handleChange}
                  fullWidth
                  name="email"
                  label="Email"
                  value={contactForm.email}
                  required
                  placeholder="Enter Email"
                ></TextField>
              </Grid>
              <Grid
                item
                xs={4}
                alignSelf={"center"}
                justifyContent={"flex-end"}
              >
                <Button variant="contained" onClick={() =>{ onSave()}}>
                  {currentId ? 'Update' : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={warningDialog} onClose={() => setWarningDialog(false)}>
        <DialogTitle>Alert</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure want to Delete Contact ??</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setWarningDialog(false)}>Cancel</Button>
          <Button onClick={()=>handleDelete()}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ContactComponent;
