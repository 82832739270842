import React, { useState } from 'react';
import * as Mui from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom';

import { NumberPad } from '../../components';
import { LW_POS_WHITE } from '../../constants';
import { loginIntoApp } from '../../services';
import { useAppDispatch } from '../../redux-store/hooks';
import { logIntoApp } from '../../redux-store/reducers';

const LoginScreen: React.FC = () => {
    const navigate = useNavigate();
    const dispatcher = useAppDispatch();
    const [employeeId, setEmployeeId] = useState('');
    const [pin, setPin] = useState('');
    const [activeField, setActiveField] = useState<'employeeId' | 'pin'>('employeeId'); // New state to track active field
    const theme = Mui.useTheme();
    const primaryColor = theme.palette.primary.main;
    const empInputBorder = activeField === "employeeId" ? `8px solid ${primaryColor}` : ``;
    const pinInputBorder = activeField === "pin" ? `8px solid ${primaryColor}` : ``;

    // Handle input changes based on the active field
    const handleInputChange = (newInput: string) => {
        if (activeField === 'employeeId') {
            setEmployeeId(newInput);
        } else {
            setPin(newInput);
        }
    };

    // Handle login attempt (replace with actual login logic)
    const handleLogin = async () => {
        if (employeeId !== '' && pin !== ' ') { // Replace with correct validation
            const response = await loginIntoApp(employeeId, pin);
            if (response && response.isSuccess) {
                dispatcher(logIntoApp({
                    token: response?.data?.token,
                    userId: response?.data?.userId
                }));
                //window.open("/app");
                navigate("/", { replace: true });
            }
        } else {
            console.error('Invalid Employee Id or PIN');
        }
    };

    return (
        <Mui.Stack height={"100vh"} flexDirection={"row"} sx={{
            //TODO
            backgroundImage: 'linear-gradient(to right, rgba(63, 81, 181, 0.8) 0%, rgba(0, 0, 0, 1) 100%)',
            backgroundSize: '100% 100%', // adjust the size to your liking
            backgroundPosition: '0% 0%',
            backgroundRepeat: 'no-repeat',
        }}>
            <Mui.Grid container>
                <Mui.Grid item xs={'auto'} md={8} lg={8} sx={{
                    display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' },
                }}>
                    <Mui.Stack height={"100vh"} justifyContent={"center"} alignItems={"center"} >
                        <img
                            src={LW_POS_WHITE}
                            alt="Product"
                            style={{ maxWidth: 500, objectFit: "fill", borderRadius: 30 }} // Stretch the image to fill the container
                        />
                    </Mui.Stack>
                </Mui.Grid>

                <Mui.Grid item xs={12} md={4} lg={4}>
                    <Mui.Stack height={"100vh"} padding={16} justifyContent={"center"} alignItems={"center"} gap={4}>
                        {/* <Mui.Typography variant='h6'>Lightworks Services POS v2.0.0</Mui.Typography> */}
                        <Mui.Stack flexDirection={"column"} spacing={2}>
                            <div style={{ borderRight: empInputBorder, width: "100%", padding: 10 }}>
                                <Mui.TextField
                                    label="Employee Id"
                                    variant='filled'
                                    type="number"
                                    value={employeeId}
                                    onFocus={() => setActiveField('employeeId')}
                                    onChange={(e) => setEmployeeId(e.target.value)}
                                    sx={{backgroundColor: theme.palette.CommonWhite.main}}
                                    fullWidth
                                />
                            </div>
                            <div style={{ borderRight: pinInputBorder, width: "100%", padding: 10, }}>
                                <Mui.TextField
                                    label="PIN"
                                    type="password"
                                    variant='filled'
                                    value={pin}
                                    onFocus={() => setActiveField('pin')}
                                    onChange={(e) => setPin(e.target.value)}
                                    inputProps={{
                                        pattern: '[0-9]*',
                                        maxLength: 10
                                    }}
                                    sx={{backgroundColor: theme.palette.CommonWhite.main}}
                                    fullWidth
                                />

                            </div>
                            <NumberPad onChange={handleInputChange}
                                value={activeField === 'employeeId' ? employeeId : pin} />
                            <Mui.Button variant="contained" color="success"
                                onClick={handleLogin} endIcon={<SendIcon />} style=
                                {{ height: 80 }}>Login</Mui.Button>
                        </Mui.Stack>
                    </Mui.Stack>
                </Mui.Grid>
            </Mui.Grid>
        </Mui.Stack>
    );
};

export { LoginScreen };
