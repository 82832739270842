import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosClient } from "../api";
import { URLS } from "../constants";
import { isResponseSuccess } from "../utils";

export const getCategoryList = createAsyncThunk(
  "categories/getCategoryList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(URLS.GET_CATEGORY);
      if(!isResponseSuccess(response.status)) {
        throw new Error("Failed to fetch categories");
      }
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.message || "Could not fetch categories");
    }
  }
);
