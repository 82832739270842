import { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper } from '@mui/material';

interface Tax {
    taxId: string;
    taxName: string;
    applicableTax: number;
    taxType: TAX_TYPE;
    isActive: boolean;
    startDate: string;
    endDate: string;
}

enum TAX_TYPE {
    SALES_TAX = 'SALES_TAX',
    INCOME_TAX = 'INCOME_TAX',
    // Add more tax types as needed
};

const ProductTax = () => {
    const [taxes, setTaxes] = useState<Tax[]>([]);

    useEffect(() => {
        // Fetch data from API or database and update the state
        setTaxes([
            {
                taxId: 'TX001',
                taxName: 'Sales Tax',
                applicableTax: 10,
                taxType: TAX_TYPE.SALES_TAX,
                isActive: true,
                startDate: '2022-01-01',
                endDate: '2022-12-31',
            },
            {
                taxId: 'TX002',
                taxName: 'Income Tax',
                applicableTax: 20,
                taxType: TAX_TYPE.INCOME_TAX,
                isActive: false,
                startDate: '2022-01-01',
                endDate: '2022-12-31',
            },
            // Add more tax data as needed
        ]);
    }, []);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Tax ID</TableCell>
                        <TableCell>Tax Name</TableCell>
                        <TableCell>Applicable Tax (%)</TableCell>
                        <TableCell>Tax Type</TableCell>
                        <TableCell>Is Active</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {taxes.map((tax) => (
                        <TableRow key={tax.taxId}>
                            <TableCell>{tax.taxId}</TableCell>
                            <TableCell>{tax.taxName}</TableCell>
                            <TableCell>{tax.applicableTax}</TableCell>
                            <TableCell>{tax.taxType}</TableCell>
                            <TableCell>{tax.isActive ? 'Yes' : 'No'}</TableCell>
                            <TableCell>{tax.startDate}</TableCell>
                            <TableCell>{tax.endDate}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export { ProductTax };