import { createAsyncThunk } from "@reduxjs/toolkit";
import { IPrinterSettings } from "../all-types";
import { axiosClient } from "../api";
import { URLS } from "../constants";
import { isResponseSuccess } from "../utils";

export const updatePrinterSettings = createAsyncThunk(
  "settings/updatePrinterSettings",
  async (details:{printerDetails: IPrinterSettings, clientId: string}, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosClient.post(
        `${URLS.UPDATE_PRINTER_SETTINGS}/${details.clientId}`,
        {
          printerSettings: details.printerDetails,
        }
      );
      if (!isResponseSuccess(response.status)) {
        throw new Error("Failed to save printer configuration.");
      }
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);