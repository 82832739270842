import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductType } from "../../../all-types";
import { STATUS } from "../../../constants";
import { getProductsList } from "../../../services";

const initialState: {
  data: ProductType[];
  status: string;
  error: string;
} = {
  data: [],
  status: STATUS.EMPTY,
  error: "",
};

export const ProductsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<ProductType[]>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductsList.pending, (state) => {
        state.status = STATUS.LOADING;
      })
      .addCase(getProductsList.fulfilled, (state, action) => {
        state.status = STATUS.SUCCESS;
        state.data = action.payload;
      })
      .addCase(getProductsList.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export const { setProducts } = ProductsSlice.actions;
