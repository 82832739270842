
import {
  Box,
  Button,
  Grid,
  SwipeableDrawer,

  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import {
  IRequestContacts,
  IRequestEmail,
  IRequestTemplate,
} from "../../../../all-types/business";
import { axiosEmailClient } from "../../../../api";
import SendIcon from "@mui/icons-material/Send";
import { MailBoxPopup } from "../components/mailbox-popup";
function MailBox() {


  const [rows, setRows] = useState<IRequestEmail[]>([]);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)

  const columns: GridColDef<(typeof rows)[number]>[] = [
    { field: "_id", headerName: "ID", minWidth: 90, flex: 1 },
    {
      field: "from",
      headerName: "From",
      minWidth: 150,

      flex: 1,
    },
    {
      field: "to",
      headerName: "To",
      minWidth: 150,

      flex: 1,
    },
    {
      field: "templateName",
      headerName: "Template",
      type: "string",
      minWidth: 150,

      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      minWidth: 160,

      flex: 1,
    },
  ];


  const fetchData = async () => {
    try {
      const req = await axiosEmailClient.get("/mail/get-mails");
      const { data } = req.data;
      console.log(data);
      setRows(data);
    } catch (err) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Box
        sx={{ height: "calc(100vh - 214px)", width: "100%", padding: "20px" }}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          width={"100%"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6" fontWeight={"400"}>
            Mails
          </Typography>
          <Button
            variant="outlined"
            startIcon={<SendIcon />}
            onClick={() => setDrawerOpen(true)}
          >
            {" "}
            Send Mails
          </Button>
        </Box>
        <DataGrid
          sx={{ marginTop: "8px" }}
          rows={rows}
          columns={columns}
          getRowId={(row: any) => row._id}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5,10,50]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
      {/* <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Send Mails</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              padding: "30px",
              border: "1px solid black",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={4} alignSelf={"center"}>
                <CustomDropDown
                  key={"cutomDrop"}
                  bindLabel={"name"}
                  bindValue={"id"}
                  onChange={handleChangeOption}
                  options={contacts}
                  name="to"
                  isMultiple={true}
                  selectedValues={[]}
                  label="Contacts"
                />
              </Grid>
              <Grid item xs={4} alignSelf={"center"}>
                <InputLabel id="mutiple-select-label">{"Subject"}</InputLabel>
                <TextField
                  sx={{ borderRadius: "6px", marginTop: "6px" }}
                  variant="outlined"
                  onChange={handleChange}
                  fullWidth
                  name="subject"
                  required
                  value={form.subject}
                  label="Subject"
                  placeholder="Enter subject"
                ></TextField>
              </Grid>
              <Grid item xs={4} alignSelf={"center"}>
                <CustomDropDown
                  key={"templateDropDown"}
                  bindLabel={"templateName"}
                  bindValue={"_id"}
                  name="templateID"
                  onChange={handleChangeOption}
                  options={templates}
                  isMultiple={false}
                  selectedValues={[]}
                  label="Templates"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4} alignSelf={"center"}>
                <TextField
                  sx={{ borderRadius: "50px" }}
                  variant="outlined"
                  value={form.text}
                  onChange={handleChange}
                  fullWidth
                  name="text"
                  label="Content"
                  required
                  placeholder="Enter Email Content"
                ></TextField>
              </Grid>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4} alignSelf={"center"} justifyContent={"flex-end"}>
              <Button variant="contained" onClick={onSave}>
                Save
              </Button>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog> */}
                  <SwipeableDrawer
                anchor={"right"}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                onOpen={() => setDrawerOpen(true)}
            >
                <Grid container width={600} mt={"64px"}>
                    <Grid item xs={12} md={12}>
                        <MailBoxPopup exit={()=>fetchData()} />
                    </Grid>
                </Grid>
            </SwipeableDrawer>
    </>
  );
}

export default MailBox;
