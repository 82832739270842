import { Box, Tab, Tabs, useTheme } from "@mui/material";
import React from "react";
import MailBox from "../MailBoxComponent/MailBox";
import { STYLE_CONSTANTS } from "../../../../constants";
import General from "../GeneralComponent/General";
import ContactComponent from "../ContactComponent/ContactComponent";
import TemplateComponent from "../TemplateComponent/TemplateComponent";
import LWStripPlugin from "../../../../plugins/stripo";
interface Tab {
  name: string;
  component: JSX.Element;
}
function EmailComponent() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const TabsData: Tab[] = [
    {
      name: "MailBox", 
      component: <MailBox/>,
    },
    {
      name: "General",
      component: <General/>,
    },
    {
      name: "Contacts",
      component: <ContactComponent/>,
    },
    {
      name: "Templates",
      component: <TemplateComponent/>,
    },
    {
      name: "Reports",
      component: <>
      <LWStripPlugin/>
      </>,
    },
  ];

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const getComponent = (index: number) => {
    return TabsData[index].component;
  };

  return (
    <>
      <Box sx={{ paddingX:`${STYLE_CONSTANTS.BUSINESS_TABLES_SPACING_IN_PX}` }}>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{borderBottom: 1, borderColor: "divider",}}
          aria-label="basic tabs example"
        >
          {TabsData.map((tab: Tab, index: number) => (
            <Tab
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "3px",
                alignItems: "center",
              }}
              key={index}
              label={tab.name}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.color12.main,
          height: `calc(100vh - ${STYLE_CONSTANTS.BUSINESS_TABLE_HEIGHT_IN_PX})`,
          overflow: 'hidden'
        }}
      >
        {typeof value === "number" && getComponent(value)}
      </Box>
    </>
  );
}

export default EmailComponent;
