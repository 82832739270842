import * as React from 'react';
import { Tabs, Tab, Stack } from '@mui/material';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import DiscountIcon from '@mui/icons-material/Discount';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

import { Printer } from '../printer';
import { ClientInfo, ClientTaxSettings } from '../client-info';

const Settings = () => {
    const [value, setValue] = React.useState(0);

    const tabContent = [
        <Stack key="profile" bgcolor={"white"}>
            <ClientInfo />
        </Stack>,
        <Stack key="printer">
            <Printer />
        </Stack>,
        <div key="display"><h2>Display Settings</h2></div>,
        <Stack key="tax-settings">
            <ClientTaxSettings />
        </Stack>,
        <div key="display"><h2>Discounts Settings</h2></div>,
    ];

    return (
        <div>
            <Tabs value={value} onChange={(e, v) => setValue(v)}>
                <Tab icon={<ManageAccountsIcon fontSize='large' />} label="Profile" aria-label="Profile Settings" />
                <Tab icon={<LocalPrintshopIcon fontSize='large' />} label="Printer" aria-label="Printer Settings" />
                <Tab icon={<PersonalVideoIcon fontSize='large' />} label="Display" aria-label="Display Settings" />
                <Tab icon={<DocumentScannerIcon fontSize='large' />} label="Tax" aria-label="Tax Settings" />
                <Tab icon={<DiscountIcon fontSize='large' />} label="Discounts" aria-label="Discounts Settings" />
            </Tabs>
            {tabContent[value]}
        </div>
    );
};

export { Settings };
