import React, { ReactNode, FC, useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './Header';
import SideBar, { DrawerHeader } from './SideBar';
import { Outlet } from 'react-router-dom';
import { STYLE_CONSTANTS } from '../constants';

interface LayoutProps {
  children?: ReactNode;
  isLoaderActive: boolean;
}

const Layout: FC<LayoutProps> = ({ children, isLoaderActive }) => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header open={open} handleDrawerOpen={handleDrawerOpen} isLoaderActive={isLoaderActive} />
      <SideBar open={open} handleDrawerClose={handleDrawerClose} />
      {/* <DrawerHeader /> */}
      <Box component="main" sx={{
        flexGrow: 1, overflow: 'auto',
        height: `calc(100vh - ${STYLE_CONSTANTS.HEADER_HEIGHT_IN_PX})`,
        marginTop: STYLE_CONSTANTS.HEADER_HEIGHT_IN_PX
      }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export { Layout };
