import { Button, Grid } from '@mui/material';
import React from 'react';

interface NumberPadProps {
    onChange: (value: string) => void;
    value: string;
}

const NumberPad: React.FC<NumberPadProps> = ({ onChange, value }) => {
    const numbers = [
        '1', '2', '3',
        '4', '5', '6',
        '7', '8', '9',
        '0', '.', 'Clear'
    ];

    const handleNumberClick = (number: string) => {
        if (number === 'Clear') {
            onChange('');
        } else {
            onChange(value + number);
        }
    };

    return (
        <Grid container spacing={1} maxWidth={270} minWidth={270}>
            {numbers.map((number, index) => (
                <Grid item key={index}>
                    {number === 'Clear' ? (
                        <Button variant="contained" color='error' style={{ width: 80, height: 80 }} onClick={() => handleNumberClick(number)}>
                            {number}
                        </Button>
                    ) : (
                        <Button variant="contained"  style={{ width: 80, height: 80 }} onClick={() => handleNumberClick(number)}>
                            {number}
                        </Button>
                    )}
                </Grid>
            ))}
        </Grid>
    );
};

export { NumberPad };