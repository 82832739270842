import { axiosClient } from "../api";
import { URLS } from "../constants";
import { isResponseSuccess } from "../utils";

export const getFileUploadURLByEvent = async (
  event: React.ChangeEvent<HTMLInputElement>
) => {
  const newFile = event.target.files?.[0];
  if (newFile) {
    const url = await getFileUploadURL(newFile);
    return url;
  }
};

export const getFileUploadURL = async (uploadFile: File) => {
  try {
    const formData = new FormData();
    formData.append("filename", uploadFile);
    const response = await axiosClient.post(URLS.FILE_UPLOAD_URL, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
    if (!isResponseSuccess(response?.status)) {
      throw new Error(response.statusText);
    }
    return response?.data?.downloadURL;
  } catch (error) {
    console.error("Error uploading file:", error);
  }
};
