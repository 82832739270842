import { BottomNavigation, BottomNavigationAction, Box, Button, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { ClientTaxList } from '../components';
import SaveIcon from '@mui/icons-material/Save';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux-store/hooks';
import { updateClientInfo } from '../../../services';


const ClientTaxSettings = () => {
    const [selectedOption, setSelectedOption] = useState("");
    const clientInfo = useAppSelector(state => state.clientInfo.data);
    const dispatcher = useAppDispatch();
    const [tmanager, setTmanager] = useState(clientInfo.taxManager);

    const handleOptionChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const val = event.target.value;
            setSelectedOption(val);
        } catch (e) {
            console.error(e);
        }
    };

    const updateTaxSettings = async () => {
        try {
            const tempClient = { ...clientInfo };
            tempClient.taxManager = {
                ...tempClient.taxManager,
                applyDefaultOrByProduct: selectedOption === "applyDefaultOrByProduct",
                applyDefaultTax: selectedOption === "applyDefaultTax",
                applyOnlyByProduct: selectedOption === "applyOnlyByProduct"
            };
            await dispatcher(updateClientInfo({
                clientId: tempClient._id,
                body: tempClient
            }));
        } catch (e) {
            console.error(e);
        }
    }

    const getSelectedOption = () => {
        const { applyDefaultTax = false,
            applyDefaultOrByProduct = false, applyOnlyByProduct = false } = tmanager;
        if (applyDefaultTax) return "applyDefaultTax";
        if (applyDefaultOrByProduct) return "applyDefaultOrByProduct";
        if (applyOnlyByProduct) return "applyOnlyByProduct";
        return "";
    }

    const initialLoad = async () => {
        try {
            const selectedOption = getSelectedOption();
            setSelectedOption(selectedOption);
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        initialLoad();
    }, []);

    return (
        <Stack p={2}>
            <RadioGroup aria-label="tax-application-options" name="tax-application-options" value={selectedOption} onChange={handleOptionChange}>
                <FormLabel component="legend">Tax Application Options</FormLabel>
                <FormControlLabel
                    value="applyDefaultTax"
                    control={<Radio />}
                    label={
                        <span>
                            Default Tax
                            <br />
                            <small>Apply the default tax rate to all products</small>
                        </span>
                    }
                />
                <FormControlLabel
                    value="applyDefaultOrByProduct"
                    control={<Radio />}
                    label={
                        <span>
                            Default or By Product
                            <br />
                            <small>Apply the default tax rate or a specific tax rate by product</small>
                        </span>
                    }
                />
                <FormControlLabel
                    value="applyOnlyByProduct"
                    control={<Radio />}
                    label={
                        <span>
                            Only By Product
                            <br />
                            <small>Apply a specific tax rate by product only</small>
                        </span>
                    }
                />
            </RadioGroup>
            <ClientTaxList />
            <BottomNavigation showLabels sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1, justifyContent: "flex-end", height: 100 }}>
                <BottomNavigationAction label="Save Settings" icon={<SaveIcon color='primary' fontSize='large' />} onClick={updateTaxSettings} />
            </BottomNavigation>
        </Stack>
    );
};

export { ClientTaxSettings };
