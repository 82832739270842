export interface TemplateData {
  buttonBgColor: string;
  contentBgColor: string;
  contentFontColor: string;
  contentLinkColor: string;
  logo: string;
  menu: { name: string; href: string }[];
  social: { name: string; href: string }[];
  socialMap: { [key: string]: { name: string; href: string } };
  generalFont: string;
}

export interface Attributes {
  attributes: { name: string; value: string }[];
}

export function extractTemplateData(
  attributes: { name?: string; value?: string }[]
): TemplateData {
  if (!attributes) {
    throw new Error("Attributes is empty");
  }

  const mapAttr: {
    [key: string]: string | undefined;
  } = {};

  attributes.forEach((attr) => {
    const name = attr.name?.slice(1, -1);
    if (name) {
      mapAttr[name] = attr.value;
    }
  });

  const result: TemplateData = {
    menu: [],
    social: [],
    socialMap: {},
    buttonBgColor: mapAttr["identity_buttons_color"] || "",
    contentBgColor: mapAttr["identity_background_color"] || "",
    contentFontColor: mapAttr["identity_body_text_color"] || "",
    contentLinkColor: mapAttr["identity_links_color"] || "",
    logo: mapAttr["identity_logo"] || "",
    generalFont: mapAttr["identity_primary_font"] || ""
  };

  attributes.forEach((attr) => {
    const { name, value } = attr;
    if (name && value) {
      const cleanName = name.slice(1, -1);
      if (cleanName.startsWith("identity_link_")) {
        const text = cleanName.replace("identity_link_", "");
        result.menu.push({
          name: text,
          href: value
        });
      } else if (cleanName.startsWith("identity_social_link_")) {
        const text = cleanName.replace("identity_social_link_", "");
        result.socialMap[text] = {
          name: text,
          href: value
        };
        result.social.push({
          name: text,
          href: value
        });
      }
    }
  });

  return result;
}
