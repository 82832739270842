// src/app/store.ts
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/user/userSlice";
import productReducer from "./features/products/productSlice";
import cartReducer from "./features/cart/cartSlice";
import alertReducer from "./features/alerts/alertSlice";
import settingsReducer from "./features/settings/settingsSlice";
import orderReducer from "./features/orders/ordersSlice";
import { CategoriesSlice, ClientInfoSlice, ProductsSlice, QuantityTypeSlice, authSlice } from "./reducers";

export const store = configureStore({
  reducer: {
    //OLD
    user: userReducer,
    products: productReducer,
    cart: cartReducer,
    alert: alertReducer,
    settings: settingsReducer,
    orders: orderReducer,
    //NEW
    clientInfo: ClientInfoSlice.reducer,
    authentication: authSlice.reducer,
    allproducts: ProductsSlice.reducer,
    categories: CategoriesSlice.reducer,
    quantityTypes: QuantityTypeSlice.reducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
