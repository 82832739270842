import React, { useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Button, Grid, LinearProgress, Typography } from '@mui/material';
import { STYLE_CONSTANTS } from '../constants';


interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: STYLE_CONSTANTS.DRAWER_WIDTH,
    width: `calc(100% - ${STYLE_CONSTANTS.DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface HeaderProps {
  open: boolean;
  handleDrawerOpen: () => void;
  isLoaderActive: boolean;
}

const Header: React.FC<HeaderProps> = ({ open, handleDrawerOpen, isLoaderActive }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  useEffect(() => {}, []);

  const handlePopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    localStorage.clear();
    window.location.reload();
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : ' ';

  return (
    <AppBar position="fixed" open={open}
    sx={{
      backgroundImage: 'linear-gradient(to right, rgba(63, 81, 181, 0.8) 0%, rgba(0, 0, 0, 1) 100%)',
      backgroundSize: '100% 100%', // adjust the size to your liking
      backgroundPosition: '0% 0%',
      backgroundRepeat: 'no-repeat',
    }}
    >
      {isLoaderActive && (
        <div className="wrapper">
          <div style={{ position: 'absolute', top: '0px', width: '100%' }}>
            <LinearProgress color="warning" />
          </div>
        </div>
      )}
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => handleDrawerOpen()}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Grid container spacing={2}>
          <Grid item xs={11} alignSelf={'center'}>
            <Typography id="exit" variant="h6" noWrap component="div" justifyContent={'center'}>
              Lightworks POS
            </Typography>
          </Grid>
          <Grid xs={1} item>
            <div
              id={id}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                gap: '4px',
              }}
              onClick={handlePopover}
            >
              <AccountCircleIcon></AccountCircleIcon>
              <Button color="warning" variant="text" onClick={logOut}>
                {' '}
                Logout
              </Button>
            </div>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
