import { createAsyncThunk } from "@reduxjs/toolkit";
import { URLS } from "../constants";
import { axiosClient } from "../api";
import { isResponseSuccess } from "../utils";
import { ClientInfoType } from "../all-types";

export const getClientInfo = createAsyncThunk(
  "clientInfo/fetchClientInfo",
  async (details: { clientId: string }, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(
        `${URLS.GET_CLIENT_INFO}/${details.clientId}`
      );
      if (!isResponseSuccess(response.status)) {
        throw new Error("Login failed");
      }
      return response.data.data;
    } catch (e: any) {
      return rejectWithValue(e.message || "Could not get client details");
    }
  }
);

export const updateClientInfo = createAsyncThunk(
  "clientInfo/updateClientInfo",
  async (
    details: { clientId: string; body: ClientInfoType },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosClient.post(
        `${URLS.UPDATE_CLIENT_INFO}/${details.clientId}`,
        {
          client: details.body,
        }
      );
      if (!isResponseSuccess(response.status)) {
        throw new Error("Login failed");
      }
      return response.data.data;
    } catch (e: any) {
      return rejectWithValue(e.message || "Could not update client details");
    }
  }
);
