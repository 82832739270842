import { axiosClient } from "../api";
import { URLS } from "../constants";

export const loginIntoApp = async (userId: string, password: string) => {
  try {
    const response = await axiosClient.post(URLS.LOGIN, { userId, password });
    if (response.status !== 200) {
      throw new Error("Login failed");
    }
    return response.data;
  } catch (e) {
    throw new Error("Login failed");
  }
};
