import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  IEmailConfiguration,
  IRequestConfiguration,
} from "../../../../all-types/business";
import { EMAIL_API_KEY, STYLE_CONSTANTS, URLS } from "../../../../constants";
import { axiosEmailClient } from "../../../../api";
import { Add } from "@mui/icons-material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
function General() {
  const [rows, setRows] = useState([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [warningDialog, setWarningDialog] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<string>("");
  
  const [configurations, setConfigurations] = useState<IEmailConfiguration>({
    name: "",
    fromEmail: "",
    smtpHost: "",
    smtpPassword: "",
    smtpPort: 0,
    smtpEmail: "",
    isActive: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;
    setConfigurations({
      ...configurations,
      [name]: type === 'checkbox' ? checked : value, // Handle checkbox (Switch) and text fields
    });
  };
  

  const onSave = async () => {
    if (!validate()) return;
    const payload: Partial<IRequestConfiguration> = {
      emailConfig: {
        from: configurations.fromEmail,
        smtp: {
          auth: {
            user: configurations.smtpEmail,
            pass: configurations.smtpPassword,
          },
          host: configurations.smtpHost,
          port: configurations.smtpPort,
        },
      },
      name: configurations.name,
      isActive: configurations.isActive,
    };

    try {
      const req = await axiosEmailClient.post(`/masters/config`, payload);
    } catch (err) {
      console.error(err);
    }
  };
  const onUpdate = async () => {
    if (!validate()) return;
    const payload: Partial<IRequestConfiguration> = {
      _id: currentId,
      emailConfig: {
        from: configurations.fromEmail,
        smtp: {
          auth: {
            user: configurations.smtpEmail,
            pass: configurations.smtpPassword,
          },
          host: configurations.smtpHost,
          port: configurations.smtpPort,
        },
      },
      name: configurations.name,
      isActive: configurations.isActive,
    };

    try {
      console.log(URLS.EMAIL_BASE_URL, EMAIL_API_KEY);
      const req = await axiosEmailClient.put(`/masters/config`, payload);
      setDialogOpen(false)
      fetchData();
    } catch (err) {
      console.error(err);
      setDialogOpen(false)
    }
  };

  const handleEdit = async (row: any) => {
    console.log(row);
    const { emailConfig } = row;

    setConfigurations(() => ({
      fromEmail: emailConfig.from,
      smtpHost: emailConfig.smtp.host,
      smtpPassword: emailConfig.smtp.auth.pass,
      smtpPort: emailConfig.smtp.port,
      smtpEmail: emailConfig.smtp.auth.user,
      name: row.name,
      isActive: row.isActive,
    }));
    setCurrentId(row._id);
    setDialogOpen(true);
  };

  const handleDelete = async () => {
    console.log(currentId);
    try {
      const req = await axiosEmailClient.delete(`/masters/config/${currentId}`);
 
      fetchData();
    } catch (err) {
      console.error(err);
    }
    setWarningDialog(false)
  };

  const validate = () => {
    return true;
  };

  const handleActiveChange = async (row: Partial<IRequestConfiguration>) => {
    console.log(row.isActive);
    try {
      const payload: any = {
        _id: row._id,
        isActive: !row.isActive,
      };

      const req = await axiosEmailClient.put("/masters/config", payload);
      fetchData();
    } catch (err) {
      console.error(err);
    }
  };

  const columns: GridColDef<Partial<IRequestConfiguration>>[] = [
    { field: "_id", headerName: "ID", minWidth: 90, flex: 1 },

    { field: "name", headerName: "Config", minWidth: 90, flex: 1 },
    {
      field: "isActive",
      headerName: "IsActive",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Switch
              checked={params.row.isActive}
              onChange={() => handleActiveChange(params.row)}
            />
          </>
        );
      },
    },
    {
      field: "eamil",
      headerName: "Sender Name",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            <div className="center-container">
              <span>{params.row.emailConfig?.from}</span>
            </div>
          </>
        );
      },
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            <div className="center-container">
              <Grid spacing={3}>
                <EditIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleEdit(params.row)}
                />
                <DeleteIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() =>{ 
                     setWarningDialog(true)
                     setCurrentId(params.row._id as string)
                } }
                />
              </Grid>
            </div>
          </>
        );
      },
      flex: 1,
    },
  ];

  const fetchData = async () => {
    const req = await axiosEmailClient.get(
      `${URLS.EMAIL_BASE_URL}/masters/config/get-config`
    );
    const { data } = req.data;
    setRows(data);
  };

  const onAdd = () => {
    setCurrentId("");
    setConfigurations(() => ({
      name: "",
      fromEmail: "",
      smtpHost: "",
      smtpPassword: "",
      smtpPort: 0,
      smtpEmail: "",
      isActive: false,
    }));
    setDialogOpen(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          overflowY: "auto",
          padding: `${STYLE_CONSTANTS.BUSINESS_TABLES_SPACING_IN_PX}`,
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          width={"100%"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6" fontWeight={"400"}>
            List Configuration
          </Typography>
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={() => onAdd()}
          >
            {" "}
            Add Item
          </Button>
        </Box>
        <Box sx={{ height: "calc(100vh - 214px)", width: "100%" }}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"end"}
          ></Box>
          <DataGrid
            sx={{ marginTop: "8px" }}
            rows={rows}
            getRowId={(row: any) => row._id}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5,10,50]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
      </Box>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Configuration</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              padding: "30px",
              border: "1px solid black",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={4} alignSelf={"center"}>
                <TextField
                  sx={{ borderRadius: "50px" }}
                  variant="outlined"
                  value={configurations.name}
                  onChange={handleChange}
                  fullWidth
                  name="name"
                  label="Name"
                  required
                  helperText=" "
                  placeholder="Enter Config Name"
                ></TextField>
              </Grid>
              <Grid item xs={4} alignSelf={"center"}>
                <TextField
                  sx={{ borderRadius: "6px" }}
                  variant="outlined"
                  onChange={handleChange}
                  fullWidth
                  name="smtpEmail"
                  helperText=" "
                  required
                  value={configurations.smtpEmail}
                  label="Sender Email"
                  placeholder="Sender Email"
                ></TextField>
              </Grid>
              <Grid item xs={4} alignSelf={"center"}>
                <TextField
                  sx={{ borderRadius: "6px" }}
                  variant="outlined"
                  onChange={handleChange}
                  fullWidth
                  label="password"
                  helperText=" "
                  name="smtpPassword"
                  value={configurations.smtpPassword}
                  required
                  placeholder="Enter Password"
                ></TextField>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4} alignSelf={"center"}>
                <TextField
                  sx={{ borderRadius: "50px" }}
                  variant="outlined"
                  value={configurations.smtpHost}
                  onChange={handleChange}
                  fullWidth
                  name="smtpHost"
                  label="smtp host"
                  required
                  helperText="Ex.smtp.gmail.com"
                  placeholder="Enter Smtp Host"
                ></TextField>
              </Grid>
              <Grid item xs={4} alignSelf={"center"}>
                <TextField
                  sx={{ borderRadius: "6px" }}
                  variant="outlined"
                  onChange={handleChange}
                  fullWidth
                  name="smtpPort"
                  label="smtp port"
                  type="number"
                  value={configurations.smtpPort}
                  required
                  placeholder="Enter Smtp Port"
                  helperText="Ex.587"
                ></TextField>
              </Grid>
              <Grid item xs={4} alignSelf={"center"}>
                <TextField
                  sx={{ borderRadius: "6px" }}
                  variant="outlined"
                  onChange={handleChange}
                  fullWidth
                  name="fromEmail"
                  value={configurations.fromEmail}
                  required
                  label="Sender Name"
                  helperText=" "
                  placeholder="Sender Name"
                ></TextField>
              </Grid>
            </Grid>
            <Grid container spacing={2}>

            <Grid item xs={4}>
            <Switch
              checked={configurations.isActive}
              name="isActive"
              onChange={handleChange}/>
            </Grid>

            <Grid item xs={4} alignSelf={"center"} justifyContent={"flex-end"}>
              <Button
                variant="contained"
                onClick={currentId ? onUpdate : onSave}
              >
                {currentId ? "Update" : "Save"}
              </Button>
            </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={warningDialog} onClose={() => setWarningDialog(false)}>
        <DialogTitle>Alert</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure want to Delete Configuration ??</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setWarningDialog(false)}>Cancel</Button>
          <Button onClick={()=>handleDelete()}>Delete</Button>
        </DialogActions>
      </Dialog>

    </>
  );
}

export default General;
