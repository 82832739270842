import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICategoryType } from "../../../all-types";
import { STATUS } from "../../../constants";
import { getCategoryList } from "../../../services";

const initialState: {
  data: ICategoryType[];
  status: string;
  error: string;
} = {
  data: [],
  status: STATUS.EMPTY,
  error: "",
};

export const CategoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<ICategoryType[]>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategoryList.pending, (state) => {
        state.status = STATUS.LOADING;
      })
      .addCase(getCategoryList.fulfilled, (state, action) => {
        state.status = STATUS.SUCCESS;
        state.data = action.payload;
      })
      .addCase(getCategoryList.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export const { setCategories } = CategoriesSlice.actions;
