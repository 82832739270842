import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const MaskLoader = () => {
    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgb(255 255 255 / 68%)',
                zIndex: 9999,
            }}
        >
            <Box sx={{ textAlign: 'center', color: '#fff' }}>
                <CircularProgress color="primary"  size={100}/>
            </Box>
        </Box>
    );
};

export { MaskLoader };
