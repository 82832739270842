import { CssBaseline, ThemeProvider } from "@mui/material";
import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { theme } from "./Theme";
import { axiosClient } from "./api";
import { STORAGE_KEYS } from "./constants";
import { AppRoutes } from "./routes";


const App: React.FC = () => {
  const [isLoaderActive, setIsLoaderActive] = useState(false);

  const showLoader = () => {
    setIsLoaderActive(true);
  };

  const hideLoader = () => {
    setIsLoaderActive(false);
  };

  axiosClient.interceptors.request.use(
    (config) => {
      showLoader();
      const token = localStorage.getItem(STORAGE_KEYS.TOKEN)
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      hideLoader();
      return Promise.reject(error);
    },
  );


  axiosClient.interceptors.response.use(
    (response) => {
      hideLoader();
      return response;
    },
    (error) => {
      hideLoader();
      // to a toast here
      return Promise.reject(error);
    },
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <AppRoutes isLoaderActive={isLoaderActive} />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
