import React, { useState } from 'react';
import { Tabs, Tab, IconButton, Box, Grid, useTheme } from '@mui/material';
import { Add } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import { BillingScreen } from './billing';
import SearchInput from '../../components/Input/SearchInput/SearchInput';
import { useProductSearch } from '../../hooks';

interface TabData {
    label: string;
}

const BillingInTabs: React.FC = () => {
    const TAB_NAME = "NEW TAB"
    const theme = useTheme();
    const [tabs, setTabs] = useState<TabData[]>([{ label: `${TAB_NAME} 1` }]);
    const [currentTab, setCurrentTab] = useState<number>(0);
    const { filteredProducts: products, searchTxt,
        searchTheProduct, setCategoryTxt, categories } = useProductSearch();

    const handleAddTab = () => {
        const newIndex = tabs.length + 1;
        setTabs([...tabs, { label: `${TAB_NAME} ${newIndex}` }]);
        setCurrentTab(tabs.length);
    };

    const handleRemoveTab = (index: number) => {
        setTabs((prevTabs) => prevTabs.filter((tab, tabIndex) => tabIndex !== index));
        if (index === currentTab) {
            setCurrentTab(Math.max(0, index - 1));
        }
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    return (
        <Box>
            <Grid container alignItems="center" bgcolor={theme.palette.CommonWhite.main} p={2}>
                <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <Tabs
                        value={currentTab}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="dynamic tabs"
                        style={{ height: 50, width: "100%" }}
                    >
                        {tabs.map((tab, index) => (
                            <Tab
                                key={index}
                                iconPosition="end"
                                sx={{
                                    height: 50,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "8px", // Adds space between the icon and the label
                                    minHeight: "auto"
                                }}
                                icon={
                                    <IconButton
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent triggering tab change when clicking the icon
                                            handleRemoveTab(index); }}>
                                        <CancelIcon color="error" />
                                    </IconButton>
                                }
                                label={tab.label}
                            />
                        ))}
                        <IconButton onClick={handleAddTab}>
                            <Add />
                        </IconButton>
                    </Tabs>
                </Grid>
                <Grid item xs={12} md={6} style={{
                    display: 'flex',
                    justifyContent: 'flex-end', alignItems: "center"
                }}>
                    <SearchInput
                        value={searchTxt}
                        onChange={(txt) => searchTheProduct(txt)}
                        placeholder="Search by Name, Product Id, Category"
                    />
                </Grid>
            </Grid>
            {tabs.map((tab, index) => (
                currentTab === index && (
                    <Box key={index}>
                        <BillingScreen products={products}
                            setCategoryTxt={setCategoryTxt} categories={categories} />
                    </Box>
                )
            ))}
        </Box>
    );
}

export { BillingInTabs };
