export const STYLE_CONSTANTS = {
  HEADER_HEIGHT_IN_PX: "64px",
  DRAWER_WIDTH: 150,
  DYNAMIC_TAB_HEIGHT_IN_PX: "50px",
  DYNAMIC_TAB_PADDING_IN_PX: "32px",
  BUSINESS_TABLES_SPACING_IN_PX: "20px 30px",
  BUSINESS_TABLE_HEIGHT_IN_PX: "186px",
  SIDEBAR_TILE_SIZE: 60,
  BILLING_PANEL_CATEGORY: "15%",
  BILLING_PANEL_ITEMS: "100%",
  BILLING_PANEL_CART: "35%",
};