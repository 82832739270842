import { createAsyncThunk } from "@reduxjs/toolkit";
import { IpaytmCardReaderSettingsType } from "../all-types";
import { axiosClient } from "../api";
import { URLS } from "../constants";

export const updatePaytmCardReaderSettings = createAsyncThunk(
  "settings/updatePaytmCardReaderSettings",
  async (
    details: { paytmCRdetails: IpaytmCardReaderSettingsType; clientId: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosClient.post(
        `${URLS.UPDATE_PAYTM_CR_SETTINGS}/${details.clientId}`,
        details.paytmCRdetails
      );
      if (!response.data.isSuccess) {
        throw new Error("Failed to save printer configuration.");
      }
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
