import React, { useState } from "react";
import {
  Card,
  CardContent,
  ListItemIcon,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';


interface DeviceCardProps {
  device: {
    vendorId: number;
    productId: number
    productName: string;
    manufacturerName: string;
  };
  index: number;
}

const PrinterCard: React.FC<DeviceCardProps> = ({ device, index }) => {
  const theme = useTheme();
  const { vendorId, productId, productName, manufacturerName } = device;
  const [isHoveredKey, setIsHoveredKey] = useState<null | number>();

  return (
    <Card
      style={{
        cursor: "pointer",
        boxShadow: isHoveredKey === index ? "5px 5px 10px #888888" : "none",
        backgroundColor: isHoveredKey === index ? theme.palette.background.default : "white",
       // color: isHoveredKey === index ? "white" : theme.palette.background.default ,
      }}
      onMouseEnter={() => setIsHoveredKey(index)}
      onMouseLeave={() => setIsHoveredKey(null)}
    >
      <CardContent>
        <Stack flexDirection={"row"} gap={2}>
          <Stack>
            <ListItemIcon style={{ fontSize: 100 }}>
              <LocalPrintshopIcon fontSize="inherit" color="primary"/>
            </ListItemIcon>
          </Stack>
          <Stack>
            <Typography variant="h5" component="h2">
              {productName}
            </Typography>
            <Typography>
              Manufacturer: {manufacturerName}
            </Typography>
            <Typography variant="body2" component="p">
              Vendor Id: {vendorId}
            </Typography>
            <Typography variant="body2" component="p">
              Product Id: {productId}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export { PrinterCard };
