import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { STORAGE_KEYS } from '../../../constants';

interface InitialStateProp {
  token: string | null;
  userId: string;
}

const initialState: InitialStateProp = {
  token: null,
  userId:''
};

export const authSlice = createSlice({
  name: 'authentication',
  initialState: initialState,
  reducers: {
    logIntoApp: (state, action: PayloadAction<{ token: string; userId: string }>) => {
      localStorage.setItem(STORAGE_KEYS.TOKEN, action.payload.token);
      localStorage.setItem(STORAGE_KEYS.USER_ID, action.payload.userId);
      state.token = action.payload.token;
    },
    logoutApp: (state) => {
      localStorage.clear();
      state.token = null;
    },
  },
});

export const { logIntoApp, logoutApp } = authSlice.actions;
