import { Box, Typography, Button, useTheme } from "@mui/material";
import BasicDatePicker from "../../components/DatePicker";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import OrdersTable from "../../components/OrdersTables";
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import { useEffect, useState } from "react";
import { axiosClient } from "../../api/axiosClient";
import dayjs from "dayjs";

declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: any) => jsPDF;
  }
}

export interface Bill {
  _id: string;
  totalAmount: number;
  modeOfPayment: string;
  billNo: string;
  clientId: string;
  billDate: string;
  billTime: string;
  tax: {
    gstInPercentage: number;
    cgstInPercentage: number;
    igstInPercentage: number;
    gstAmount: number;
    cgstAmount: number;
    igstAmount: number;
    isCompositionDealer: boolean;
  };
  discount: {
    isDiscountApplied: boolean;
    discountInPercentage: number;
    discountAmount: number;
  };
  items: any[]; // Replace any with your item type
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface Data {
  sno: number;
  orderId: string;
  orderDate: string;
  quantity: number;
  modeOfPayment: string;
  amount: number;
}

const Dashboard = () => {
  const theme = useTheme();
  const [data, setData] = useState<Data[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const [todayOrdersCount, setTodayOrdersCount] = useState<number>(0);
  const [todayTotalAmount, setTodayTotalAmount] = useState<number>(0);
  const [todayCashAmount, setTodayCashAmount] = useState<number>(0);
  const [todayUPIAmount, setTodayUPIAmount] = useState<number>(0);
  const [todayCardAmount, setTodayCardAmount] = useState<number>(0);

  useEffect(() => {
    fetchBills();
  }, []);

  const fetchBills = async () => {
    try {
      const startTime = fromDate.startOf("day").valueOf();
      const endTime = toDate.endOf("day").valueOf();
      const response = await axiosClient.post(
        `/v1/admin/private/api/get-bills?startTime=${startTime}&endTime=${endTime}`
      );
      if (response.data.isSuccess) {
        let todayCount = 0;
        let todayAmount = 0;
        let todayCashAmount = 0;
        let todayUPIAmount = 0;
        let todayCardAmount = 0;
        const today = new Date();
        const formattedToday = today.toISOString().slice(0, 10); // Ensure this matches your date format

        const transformedData: Data[] = response.data.data.bills.map(
          (bill: Bill, index: number) => {
            const billDate = new Date(bill.createdAt)
              .toISOString()
              .slice(0, 10);
            if (billDate === formattedToday) {
              todayCount += 1;
              todayAmount += bill.totalAmount;
              if (bill.modeOfPayment === "Cash") {
                todayCashAmount += bill.totalAmount;
              }
              if (bill.modeOfPayment === "UPI") {
                todayUPIAmount += bill.totalAmount;
              }
              if (bill.modeOfPayment === "Card") {
                todayCardAmount += bill.totalAmount;
              }
            }

            return {
              sno: index + 1,
              orderId: bill.billNo,
              orderDate: formatDateToIST(bill.createdAt),
              quantity: bill.items.length,
              modeOfPayment: bill.modeOfPayment,
              amount: bill.totalAmount,
            };
          }
        );

        setTodayOrdersCount(todayCount);
        setTodayTotalAmount(todayAmount);
        setTodayCashAmount(todayCashAmount);
        setTodayUPIAmount(todayUPIAmount);
        setTodayCardAmount(todayCardAmount);
        setData(transformedData);
      } else {
        setError("Failed to fetch bills");
      }
    } catch (error: any) {
      setError(
        error.response?.data?.message ||
          error.message ||
          "An unknown error occurred"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const formatDateToIST = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false, // Use 24-hour format
    };
    return new Intl.DateTimeFormat("en-IN", options).format(date);
  };

  const today = new Date();
  const formattedDate = `${today.getDate().toString().padStart(2, "0")}/${(
    today.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${today.getFullYear()}`;


  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ mr: 2, fontWeight: "bold" }}>OVERVIEW</Typography>
        <Typography variant="subtitle1">{formattedDate}</Typography>
      </Box>
      <Box
        sx={{ display: "flex", my: 3, gap: 3, justifyContent: "space-around" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#FFF5E5",
            padding: "1rem",
            borderRadius: 2,
          }}
        >
          <Box sx={{ fontWeight: "bold", fontSize: "2rem", color: "#C97C00" }}>
            {todayOrdersCount}
          </Box>
          <Typography sx={{ fontSize: "0.8rem" }}>
            Today's total orders
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#EEF9F5",
            padding: "1rem",
            borderRadius: 2,
          }}
        >
          <Box sx={{ fontWeight: "bold", fontSize: "2rem", color: "#007248" }}>
            ₹ {todayTotalAmount}
          </Box>
          <Typography sx={{ fontSize: "0.8rem" }}>Total Amount</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#EEF9F5",
            padding: "1rem",
            borderRadius: 2,
          }}
        >
          <Box sx={{ fontWeight: "bold", fontSize: "2rem", color: "#007248" }}>
            ₹ {todayCashAmount}
          </Box>
          <Typography sx={{ fontSize: "0.8rem" }}>Total Cash Amount</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#EEF9F5",
            padding: "1rem",
            borderRadius: 2,
          }}
        >
          <Box sx={{ fontWeight: "bold", fontSize: "2rem", color: "#007248" }}>
            ₹ {todayUPIAmount}
          </Box>
          <Typography sx={{ fontSize: "0.8rem" }}>Total UPI Amount</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#EEF9F5",
            padding: "1rem",
            borderRadius: 2,
          }}
        >
          <Box sx={{ fontWeight: "bold", fontSize: "2rem", color: "#007248" }}>
            ₹ {todayCardAmount}
          </Box>
          <Typography sx={{ fontSize: "0.8rem" }}>Total Card Amount</Typography>
        </Box>
      </Box>
      <OrdersTable bills={data} />
    </Box>
  );
};

export default Dashboard;
