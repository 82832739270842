import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  Category,
  CategoryApiResponse,
  Product,
  ProductsState,
} from "../../../constants/Interfaces";
import {axiosClient} from "../../../api/axiosClient";

const initialState: ProductsState = {
  products: [],
  categories: [],
  status: "idle",
  error: null,
  selectedProductForDeletion: null,
  selectedProductForEdition: null,
  selectedProductForBilling: null,
  editProductForBilling: null,
  isAddCategoryModalOpen: false,
};

interface ProductsResponse {
  data: Product[];
}

export const fetchProducts = createAsyncThunk<
  Product[],
  void,
  { rejectValue: string }
>("products/fetchProducts", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosClient.get<ProductsResponse>(
      "/v1/admin/private/api/get-product-list?limit=10000"
    );
    return response.data.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchCategories = createAsyncThunk<
  Category[],
  void,
  { rejectValue: string }
>("categories/fetchCategories", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosClient.get<CategoryApiResponse>(
      "/v1/admin/private/api/get-category-list"
    );
    if (response.data.isSuccess) {
      return response.data.data;
    } else {
      throw new Error("Failed to fetch categories");
    }
  } catch (error: any) {
    return rejectWithValue(error.message || "Could not fetch categories");
  }
});

export const deleteProduct = createAsyncThunk<
  void,
  string,
  { rejectValue: string }
>(
  "products/deleteProduct",
  async (productId, { dispatch, rejectWithValue }) => {
    try {
      await axiosClient.post(
        `/v1/admin/private/api/delete-product/${productId}`
      );
      dispatch(fetchProducts());
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data || "Could not delete the product"
      );
    }
  }
);

export const createCategory = createAsyncThunk<
  void,
  string,
  { rejectValue: string }
>("categories/createCategory", async (categoryName, { rejectWithValue }) => {
  try {
    const response = await axiosClient.post(
      "/v1/admin/private/api/create-category",
      {
        categoryName: categoryName,
      }
    );
    if (!response.data.isSuccess) {
      throw new Error("API call successful but failed to create category");
    }
  } catch (error: any) {
    return rejectWithValue(error.message || "Could not create category");
  }
});

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setSelectedProductForDeletion(state, action) {
      state.selectedProductForDeletion = action.payload;
    },
    clearSelectedProductForDeletion(state) {
      state.selectedProductForDeletion = null;
    },
    setSelectedProductForEdition(state, action) {
      state.selectedProductForEdition = action.payload;
    },
    clearSelectedProductForEdition(state) {
      state.selectedProductForEdition = null;
    },
    setSelectedProductForBilling(state, action) {
      state.selectedProductForBilling = action.payload;
    },
    setEditProductForBilling(state, action) {
      state.editProductForBilling = action.payload;
    },
    clearSelectedProductForBilling(state) {
      state.selectedProductForBilling = null;
    },
    clearItemForBilling(state) {
      state.editProductForBilling = null;
    },
    openAddCategoryModal(state) {
      state.isAddCategoryModalOpen = true;
    },
    closeAddCategoryModal(state) {
      state.isAddCategoryModalOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Something went wrong";
      })
      .addCase(deleteProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteProduct.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to delete product";
      })
      .addCase(fetchCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch categories";
      })
      .addCase(createCategory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCategory.fulfilled, (state) => {
        state.status = "succeeded";
        state.isAddCategoryModalOpen = false;
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to create category";
      });
  },
});

export const {
  setSelectedProductForDeletion,
  setEditProductForBilling,
  clearSelectedProductForDeletion,
  setSelectedProductForEdition,
  clearSelectedProductForEdition,
  setSelectedProductForBilling,
  clearSelectedProductForBilling,
  openAddCategoryModal,
  closeAddCategoryModal,
  clearItemForBilling,
} = productSlice.actions;

export default productSlice.reducer;
