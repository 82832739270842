import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, SvgIconTypeMap } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import EmailComponent from "./Email/EmailComponent/EmailComponent";
interface Tab {
  name: string;
  icon: any;
  component: JSX.Element;
}
function Business() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const TabsData: Tab[] = [
    {
      name: "Email",
      icon: <MailIcon />,
      component: <EmailComponent />,
    },
    {
      name: "Whatsapp",
      icon: <WhatsAppIcon />,
      component: <EmailComponent />,
    },
  ];

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const getComponent = (index: number) => {
    return TabsData[index].component;
  };

  return (
    <>
      <Box >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {TabsData.map((tab: Tab, index: number) => (
            <Tab
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "3px",
                alignItems: "center",
              }}
              key={index}
              label={tab.name}
              icon={tab.icon}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.color12.main,
        }}
      >
        {typeof value === "number" && getComponent(value)}
      </Box>
    </>
  );
}

export default Business;
