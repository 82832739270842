import * as Mui from '@mui/material';
import React, { useEffect } from 'react';
import { LW_POS_WHITE } from '../../constants';
import { useAppDispatch } from '../../redux-store/hooks';
import { getClientInfo } from '../../services';
import { AppConfig } from '../../config';

const StartupLoader: React.FC = () => {
    const theme = Mui.useTheme();//TODO
    const dispatcher = useAppDispatch();

    useEffect(() => {
        setTimeout(() => {
            dispatcher(getClientInfo({ clientId: AppConfig.getCLientId() || ""}));
        }, 2000);
    }, [dispatcher]);

    return (
        <Mui.Stack height={"100vh"} gap={2} justifyContent={"center"}
            alignItems={"center"} 
            sx={{//TODO
                backgroundImage: 'linear-gradient(to right, rgba(63, 81, 181, 0.8) 0%, rgba(0, 0, 0, 1) 100%)',
                backgroundSize: '100% 100%', // adjust the size to your liking
                backgroundPosition: '0% 0%',
                backgroundRepeat: 'no-repeat',
              }}
            >
            <img
                src={LW_POS_WHITE}
                alt="Product"
                style={{ maxWidth: 500, objectFit: "fill", borderRadius: 30 }}
            />
            <Mui.Box sx={{ width: 200 }}>
                <Mui.LinearProgress />
            </Mui.Box>
        </Mui.Stack>
    );
};

export { StartupLoader };

