import React from "react";
import {
  Box,
  IconButton,
  Typography,
  Divider,
  Button,
  Badge,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { RootState } from "../../redux-store/store";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCart,
  removeItemFromCart,
} from "../../redux-store/features/cart/cartSlice";
import DottedLine from "../Molecules/DottedLine";
import { CartItem } from "./BillingComponent";
import { useAppSelector } from "../../redux-store/hooks";
import { Product } from "../../constants/Interfaces";
import {
  setEditProductForBilling,
  setSelectedProductForBilling,
} from "../../redux-store/features/products/productSlice";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

import { useTheme } from "@mui/material/styles";

const CartComponent = ({
  changeTab,
  handleOpen,
}: {
  changeTab: () => void;
  handleOpen: () => void;
}) => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const products = useAppSelector((state) => state.products.products);

  const theme = useTheme();

  const handleEditIcon = (item: CartItem) => {
    // Use the find method to get the first product that matches the condition
    const matchedProduct = products.find(
      (product: Product) => product._id === item.id.split("-")[0]
    );

    if (matchedProduct) {
      dispatch(setSelectedProductForBilling(matchedProduct));
      dispatch(setEditProductForBilling(item));
      handleOpen();
    } else {
      console.log("No product matched the given cart item's ID.");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", flexDirection: "row", mb: 2 }}>
          <Typography
            textAlign="left"
            sx={{
              fontWeight: "bold",
              fontSize: "1.375rem",
            }}
          >
            Cart
          </Typography>
          <Badge
            badgeContent={cartItems.length === 0 ? "0" : cartItems.length}
            color="primary"
            overlap="circular"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <ShoppingCartOutlinedIcon
              sx={{
                ml: 2,
                color: theme.palette.color2.main,
                backgroundColor: theme.palette.color9.main,
                borderRadius: "50%",
                width: "2.1875rem",
                height: "2.1875rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.4375rem",
                padding: "6px",
                position: "relative", // Ensure the badge positions correctly
              }}
            />
          </Badge>
        </Box>
        {cartItems.length === 0 ? (
          <Typography
            textAlign="left"
            sx={{
              fontSize: "1rem", // 16px (1 * 16 = 16)
            }}
          >
            Your cart is Empty, click “Add +” to add items to the cart!
          </Typography>
        ) : (
          <Box>
            <Box sx={{ display: "flex", width: "100%" }}>
              {/* Adjusted header section to match the items layout */}
              <Typography
                sx={{
                  flex: 1,
                  textAlign: "left",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                S.No
              </Typography>
              <Typography
                sx={{
                  flex: 2,
                  textAlign: "left",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Item
              </Typography>
              <Typography
                sx={{
                  flex: 1,
                  textAlign: "left",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Qty
              </Typography>
              <Typography
                sx={{
                  flex: 1,
                  textAlign: "left",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Amount
              </Typography>
              <Typography
                sx={{
                  flex: 2,
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Actions
              </Typography>
            </Box>
            {/* Assuming DottedLine is a component you have that renders a styled divider */}
            <DottedLine />
            {cartItems.map((item, index) => (
              <Box
                key={item.productCode}
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center", // Center items vertically within each row
                  justifyContent: "space-between",
                  my: 1,
                }}
              >
                <Typography
                  sx={{ flex: 1, textAlign: "left", fontSize: "12px" }}
                >
                  {index + 1}
                </Typography>
                <Typography
                  sx={{ flex: 2, textAlign: "left", fontSize: "12px" }}
                >
                  {item.name}
                </Typography>
                <Typography
                  sx={{ flex: 1, textAlign: "center", fontSize: "12px" }}
                >
                  {item.quantity}
                </Typography>
                <Typography
                  sx={{ flex: 1, textAlign: "center", fontSize: "12px" }}
                >
                  {item.amount}
                </Typography>
                <Box
                  sx={{ flex: 2, display: "flex", justifyContent: "center" }}
                >
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() => handleEditIcon(item)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      dispatch(removeItemFromCart(item.id));
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>

      <Box sx={{ mb: 2 }}>
        {/* <Divider orientation="horizontal" flexItem />
        <Typography textAlign="center" sx={{ mt: 2 }}>
          Total Price:{" "}
          <b>INR {cartItems.reduce((acc, val) => acc + val.amount, 0)}</b>
        </Typography> */}
        <Box sx={{ display: "flex", justifyContent: "space-evenly", mt: 2 }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.color10.main,
              borderRadius: "8px",
              fontSize: "1rem",
              px: "1.25rem",
              py: "0.75rem",
              textTransform: "none",
            }}
            onClick={() => dispatch(clearCart())}
            disabled={!cartItems.length}
          >
            Clear Cart
          </Button>
          <Button
            variant="contained"
            onClick={changeTab}
            sx={{
              fontSize: "1rem",
              backgroundColor: theme.palette.color11.main,
              borderRadius: "8px",
              px: "1.25rem",
              py: "0.75rem",
              textTransform: "none",
            }}
          >
            Place Order
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CartComponent;
