import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { STATUS, STORAGE_KEYS } from '../constants';
import { Layout } from '../layout';
import { useAppDispatch, useAppSelector } from '../redux-store/hooks';
import { logIntoApp } from '../redux-store/reducers';
import { MaskLoader, StartupLoader } from '../screens';


interface PrivateRouteProps {
  children: JSX.Element;
  isLoaderActive: boolean;
}

const DashboardPrivateRoute: FC<PrivateRouteProps> = ({ children, isLoaderActive }) => {
  const navigate = useNavigate();
  const dispatcher = useAppDispatch();
  const authToken = useAppSelector(state => state.authentication.token);
  const loadState = useAppSelector(state => state.clientInfo.status);

  useEffect(() => {
    const token = localStorage.getItem(STORAGE_KEYS.TOKEN);
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    if (token && userId) {
      dispatcher(logIntoApp({ token, userId }));
    } else {
      navigate('/login');
    }
  }, [authToken, loadState, navigate, dispatcher]);


  if (loadState !== STATUS.SUCCESS) {
    return (
      <StartupLoader />
    )
  }

  return (
    <>
      {isLoaderActive && <MaskLoader />}
      <Layout isLoaderActive={isLoaderActive}>{children}</Layout>
    </>
  );
};

export default DashboardPrivateRoute;
