import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { QUANTITY_TYPES } from "../../../all-types";
import { STATUS } from "../../../constants";

const initialState: {
  types: QUANTITY_TYPES[];
  shortNames: string[];
  status: string;
  error: string;
} = {
  types: Object.values(QUANTITY_TYPES),
  shortNames: [],
  status: STATUS.EMPTY,
  error: "",
};

export const QuantityTypeSlice = createSlice({
  name: "quantityTypes",
  initialState,
  reducers: {
    setQuantityTypes: (state, action: PayloadAction<QUANTITY_TYPES[]>) => {
      state.types = action.payload;
    },
  },
});

export const { setQuantityTypes } = QuantityTypeSlice.actions;
